import { useEffect, useRef, useState } from 'react';
import { ENTER_KEY_CODE, ESCAPE_KEY_CODE } from '../../../shared/helpers/accessibilityHelpers';

export default function Dropdown(props) {

  const [isOpen, setIsOpen] = useState(false);
  const [focusedIndex, setFocusedIndex] = useState(-1);

  const toggle = () => setIsOpen(!isOpen);

  function optionClicked(opt) {
    setIsOpen(false);
    props.onChange(opt);
  }

  var tempClass = 'time-arrow ';
  tempClass += isOpen ? 'up ' : 'down ';

  var dropdownClass = 'dropdown-list ';
  dropdownClass += isOpen ? 'unhide' : 'hide';

  const node = useRef();

  const clickOutside = (e) => {
    if (node?.current?.contains(e.target)) {
      return;
    }
    setIsOpen(false)
  }

  useEffect(() => {
    document.addEventListener('mousedown', clickOutside);
    return () => {
      document.removeEventListener('mousedown', clickOutside);
    }
  }, [isOpen])

  function onKeyDown(e) {
    const totalOptions = props.options.length;
    if (e.key === 'ArrowDown') {
      e.preventDefault();
      setFocusedIndex((prevIndex) => (prevIndex + 1) % totalOptions);
    } else if (e.key === 'ArrowUp') {
      e.preventDefault();
      setFocusedIndex((prevIndex) => (prevIndex - 1 + totalOptions) % totalOptions);
    } else if (e.which === ENTER_KEY_CODE) {
      e.preventDefault();
      if (isOpen) {
        if (focusedIndex >= 0) {
          optionClicked(props.options[focusedIndex]);
        }
      } else {
        toggle();
      }
    } else if (e.which === ESCAPE_KEY_CODE) {
      e.preventDefault();
      setIsOpen(false);
    }
  }

  function onStateSelectKeyDown(e, option) {
    if (e.which === ENTER_KEY_CODE) {
      optionClicked(option);
    }
  }

  useEffect(() => {
    if (isOpen && focusedIndex >= 0) {
      const focusedElement = document.getElementById(props.options[focusedIndex]);
      if (focusedElement) {
        focusedElement.focus();
      }
    }
  }, [focusedIndex, isOpen, props.options]);

  return (<div className='flexibility-container' ref={node}>
    <div className='flexibility-label'>
      <label id={props.name}>{props.label || ' '}</label>
    </div>
    <div
      className={props.error ? 'flexibility-header error' : 'flexibility-header'}
      tabIndex={0}
      role='button' aria-label={`${props.name} ${props.value}`}
      aria-controls={`${props.name}-listbox`}
      aria-expanded={isOpen}
      onClick={() => toggle()} id={`${props.name}button`}
      onKeyDown={onKeyDown}>
      {props.value ?
        <div className='placeholder'>
          {props.value}
        </div> :
        <div className='placeholder-content'>
          {props.placeholder || ''}
        </div>
      }
      <i className={tempClass}></i>
    </div>
    {isOpen && <div
      className="dropdown-container"
      id={`${props.name}-listbox`}
      role="listbox"
      aria-activedescendant={props.options[focusedIndex] || ''}
    >
      <div className={dropdownClass}>
        {props.options.map((option, index) =>
          <div
            role='option'
            className='list-item'
            aria-selected={option === props.value}
            addedclassname={option === props.value ? 'selected' : ''}
            tabIndex={0} id={option}
            onClick={() => optionClicked(option)}
            onKeyDown={(e) => onStateSelectKeyDown(e, option)}
            key={option}
            onFocus={() => setFocusedIndex(index)}
            aria-labelledby={`${option}-label`}>{option}</div>
        )}
      </div>
    </div>}
  </div>);
}