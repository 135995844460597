import {
  ADD_CARD,
  DISPLAY_ERROR,
  GET_BIN_NUMBERS,
  GET_EMPLOYERS_LIST,
  GET_EMPLOYERS_LIST_ERROR,
  GET_MATCHING_VANPOOLS,
  GET_MATCHING_VANPOOLS_ERROR,
  GET_PRORATED_AMOUNT,
  GET_PRORATED_AMOUNT_SUCCESS,
  GET_VANPOOL_INFO,
  GET_VANPOOL_INFO_SUCESS,
  GET_VANPOOL_PARTICIPANTS,
  GET_VANPOOL_PARTICIPANTS_SUCESS,
  SAVE_SUBSCRIPTION_SUMMARY,
  SAVE_SUBSCRIPTION_SUMMARY_FAILURE,
  SAVE_SUBSCRIPTION_SUMMARY_SUCCESS,
  SET_BIN_NUMBERS,
  SET_EMPLOYERS_LIST,
  SET_MATCHING_VANPOOLS,
  SET_USER_INFO,
  SET_USER_INFO_FAILURE,
  SET_USER_INFO_SUCCESS,
  UPLOAD_PROFILE_PICTURE,
  UPLOAD_PROFILE_PICTURE_FAILURE,
  UPLOAD_PROFILE_PICTURE_SUCCESS,
  USER_PROFILE_LOADING,
  APPLY_TO_SIGN_CONTRACT,
  SAVE_CONTRACT_DETAILS,
  LOADER_ACTIVE,
  VERIFY_CARD,
  SET_USER_EMPLOYER_DATA,
  PRIVATE_VANPOOL_SEARCH,
  SET_USER_DRIVING_SCHEDULE,
  SET_USER_COMMUTE_DATA,
  UPDATE_USER_COMMUTE_DATA,
  INVITE_VANPOOL_SEARCH
} from '../redux/actionTypes';
import { call, put, select, takeLatest, all } from 'redux-saga/effects';
import Http from '../shared/http-service';
import logger from '../shared/logger';
import userService from '../shared/user-service';
import config from '../appConfig';
import { getCardsArray } from './helpers/vanHelpers';
import paymentProcessor from '../shared/credit-card/paymentProcessor';
import MapService from '../shared/map-service';
import UserService from '../shared/user-service';
import {
  getActiveDaysFromRoosterDays
} from '../join-commute/helpers/vanHelpers';
import { getUserPackage } from '../join-commute/helpers/commonHelper';
import AddressUtility from '../shared/addressUtility';
import localStorageService from '../shared/localStorageService';
import { getMaskedCardNumber } from '../shared/helpers/cardHelpers';

function* getEmployersList(data) {
  const userInfo = userService.getAuthentication();
  try {
    const commuteUrl = `${config.kongApiBaseUrl}/user/profile/commuteProfile`;
    const employersURL = `${config.kongApiBaseUrl}/user/profile/employer/search?latitude=${data.data.workLatitude}&longitude=${data.data.workLongitude}`;
    const result = yield Http.get(employersURL);

    if (result && result.employers) {
      yield put({ type: SET_EMPLOYERS_LIST, data: result.employers });
    } else {
      yield put({ type: GET_EMPLOYERS_LIST_ERROR });
    }
  } catch (e) {
    logger.log(e);
    yield put({ type: GET_EMPLOYERS_LIST_ERROR });
  }
}

function* findVanpoolMatches(action) {
  const state = yield select();
  var request = state.joinCommute.userCommuteData;
  if (state.joinCommute.employerData.employerId) {
    request.employerId = state.joinCommute.employerData.employerId;
    request.employerName = null;
  } else {
    request.employerId = null;
    request.employerName = state.joinCommute.employerData.employerName;
  }
  request.isDesiredDriverRole =
    state.joinCommute.drivingScheduleData.isDesiredDriverRole;
  const vanpoolSearchUrl = `${config.kongApiBaseUrl}/vanpools/search/count`;
  if (request.arriveTime) {
    try {
      const result = yield call(() => Http.post(vanpoolSearchUrl, request));
      if (result && result.vanpoolMatches.length) {
        yield put({ type: SET_MATCHING_VANPOOLS, data: {...result, inviteId: state.joinCommute.userCommuteData && state.joinCommute.userCommuteData.inviteId} });
      } else {
        yield put({ type: GET_MATCHING_VANPOOLS_ERROR, data: { vanpoolMatches: [], impactMetrics: null } });
      }
      if (action && action.history) {
        action.history.push("/JoinCommute/view-matched-vans");
      } else if (action.data.privateVanpool) {
        window.location.href = `${window.location.origin}/#/JoinCommute/view-matched-vans`;
      }
    } catch (ex) {
      logger.log(ex);
      yield put({ type: GET_MATCHING_VANPOOLS_ERROR, data: { vanpoolMatches: [], impactMetrics: null } });
      if (action && action.history) {
        action.history.push('/JoinCommute/error');
      }
    }
  }
}

function* inviteVanpoolMatches(action) {
  var inviteId = action.data.inviteId;
  const vanpoolSearchUrl = `${config.kongApiBaseUrl}/vanpools/search/count`;
  var paylaod = {
    "inviteId": inviteId  // "111E8CFD-8BFE-8BBC-6F8C-7E81A254BB7"   //replace inviteid
  }
  if (inviteId) {
    try {
      const result = yield call(() => Http.post(vanpoolSearchUrl, paylaod));
      yield call(() => result && saveUserCommuteData(result));
     // yield call(() => result && saveCommuteProfile(result));
      result.inviteId = inviteId;
      yield put({
        type: SET_USER_DRIVING_SCHEDULE, data: {
          isDesiredDriverRole: result.isDriverInvite,
          role: result.isDriverInvite ? 0 : 2,
          isFrequentDriver: null,
        }
      })
      if (result && result.vanpoolMatches.length) {
        yield put({ type: SET_MATCHING_VANPOOLS, data: { ...result, isInviteError: false } });
        window.location.href = `${window.location.origin}/#/JoinCommute/view-matched-vans`;
      } else {
        yield put({ type: SET_MATCHING_VANPOOLS, data: { vanpoolMatches: [], impactMetrics: null, inviteId, isInviteError: false, isCoordinatorInvite: result ? result.isCoordinatorInvite : false } });
        window.location.href = `${window.location.origin}/#/JoinCommute/view-matched-vans`;
      }
    } catch (ex) {
      if (ex && (ex.fetchedStatusCode && ex.fetchedStatusCode === 400) && (ex.message && ex.message == "Invalid Invite Id")) {
        yield put({ type: SET_MATCHING_VANPOOLS, data: { vanpoolMatches: [], impactMetrics: null, inviteId, isInviteError: true, isCoordinatorInvite: false } });
        localStorageService.clear('inviteId');
        window.location.href = `${window.location.origin}/#/JoinCommute/view-matched-vans`;
      } else {
        yield put({ type: GET_MATCHING_VANPOOLS_ERROR, data: { vanpoolMatches: [], impactMetrics: null, inviteId, isCoordinatorInvite: false } });
        if (action && action.history) {
          action.history.push('/JoinCommute/error');
        }
      }
    }
  }
}

function* saveUserCommuteData(data) {
  const [homeAddress, workAddress] = yield all([
    call(() => MapService.getAddressByLatLang(parseFloat(data.homeLatitude), parseFloat(data.homeLongitude))),
    call(() => MapService.getAddressByLatLang(parseFloat(data.workLatitude), parseFloat(data.workLongitude)))
  ]);
  data.homeAddress = homeAddress;
  data.workAddress = workAddress;
  const startAddress = yield call(() => MapService.getAddressAndLocation(homeAddress));
  data.homeAddressExpanded = AddressUtility.fromGeocode(startAddress);
  var activeDays = Object.values(data.rosterDays).filter(val => val == true);
  data.package = getUserPackage(activeDays.length);
  var employerData = {
    employerId: data.employerId ? data.employerId : null,
    employerName: data.employerName ? data.employerName : null
  };
  yield put({ type: SET_USER_EMPLOYER_DATA, data: employerData });
  yield put({ type: UPDATE_USER_COMMUTE_DATA, data: data, updateOldPlan: true });
}

function* saveUserInfo(data) {
  const requestToJoinUrl = `${config.kongApiBaseUrl}/vanpool/requestToJoin`;
  try {
    const res = yield call(() => Http.post(requestToJoinUrl, data.data));
    if ( res.success) {
      yield put({
        type: SET_USER_INFO_SUCCESS, data: true
      });
    } else {
      yield put({
        type: SET_USER_INFO_FAILURE,
      });
    }
  } catch (ex) {
    logger.log(ex);
  }
}

function* getBINNumbers() {
  const url = `${config.kongApiBaseUrl}/payment/recurringChargeInformation`;
  try {
    const result = yield call(() => Http.get(url));
    if (result && result.recurringChargeInformation) {
      const chargeInfo = result.recurringChargeInformation;
      const maxAmount = result.maxFederalBenefitAmount;
      const binNumbers = chargeInfo.map(charge => charge.binCode);
      yield put({
        type: SET_BIN_NUMBERS, data: { binNumbers: binNumbers, maxAmount: maxAmount }
      });
    }
  }
  catch (e) {
    logger.log(e);
    return [];
  }
}

function* verifyPayment(action) {
  const state = yield select();
  const vanpoolId = state.joinCommute.selectedVan && state.joinCommute.selectedVan.vanpoolId;
  try {
    const verifyPaymentURL = `${config.kongApiBaseUrl}/participant/payment/method/verify`;
    let processorResponse = yield paymentProcessor.addCard(action.saveInfo);

    let response = yield call(() =>
      Http.post(verifyPaymentURL, processorResponse, { vanpoolId })
    );

    if (response.success) {
      const maskedCreditCardNumber = getMaskedCardNumber(action.data.cardNumber);
      yield put({
        type: ADD_CARD,
        data: { ...action.data, token: processorResponse.tokenId, maskedCreditCardNumber }
      });
    } else {
      if (action && action.history) {
        action.history.push('/JoinCommute/error');
      }
    }
  } catch (ex) {
    logger.log(ex);
    if (action && action.history) {
      action.history.push('/JoinCommute/error');
    }
  }
}


function* getProratedAmount(action) {
  const state = yield select();
  const commuteData = state.joinCommute.userCommuteData;
  const cards = getCardsArray(state.joinCommute.payment.cards, true);
  const vanpoolId = state.joinCommute.selectedVan && state.joinCommute.selectedVan.vanpoolId;
  const selectedVan = state.joinCommute.selectedVan;
  if (commuteData && cards && vanpoolId) {
    const request = {
      destinationLatitude: selectedVan.matchedDestinationStop.latitude,
      destinationLongitude: selectedVan.matchedDestinationStop.longitude,
      originLatitude: selectedVan.matchedOriginStop.latitude,
      originLongitude: selectedVan.matchedOriginStop.longitude,
      subscriptionType: 'Fixed',
      paymentMethods: cards,
      packageDiscountType: state.joinCommute.drivingScheduleData.isDesiredDriverRole ? 'driver' : 'rider',
      packageName: commuteData.package,
      employerId: commuteData.employerId
    }
    if(action.startDate){
      request.startDate = action.startDate
    }
    try {
      const proratedURL = `${config.kongApiBaseUrl}/vanpools/vanpool/subscription/summary`;
      let response = yield call(() =>
        Http.post(proratedURL, request, { vanpoolId })
      );

      if (response) {
        yield put({
          type: GET_PRORATED_AMOUNT_SUCCESS,
          data: response
        });
      } else {
        if (action && action.history) {
          action.history.push('/JoinCommute/error');
        }
      }
    } catch (ex) {
      logger.log(ex);
      if (action && action.history) {
        action.history.push('/JoinCommute/error');
      }
    }
  }
}

function* saveSummary(action) {
  const state = yield select();
  const commuteData = state.joinCommute.userCommuteData;
  const employerData = state.joinCommute.employerData;
  const cards = getCardsArray(state.joinCommute.payment.cards, false);
  const vanpoolId = state.joinCommute.selectedVan && state.joinCommute.selectedVan.vanpoolId;
  const selectedVan = state.joinCommute.selectedVan;
  const contractInfo = state.joinCommute.contractInfo;
  const summaryData = commuteData.summaryData;
  const driverCoordInformation = state && state.profile && state.profile.driverApplicationData && state.profile.driverApplicationData.apiData && state.profile.driverApplicationData.apiData.driverCoordInformation;
  if (driverCoordInformation) {
    driverCoordInformation.agreementId = contractInfo.agreementId ? contractInfo.agreementId : null;
    driverCoordInformation.clickwrapId = contractInfo.clickwrapId ? contractInfo.clickwrapId : null
  }
  const inviteId = state.joinCommute.inviteId ? state.joinCommute.inviteId : null;
  if (commuteData && cards && vanpoolId) {
    const request = {
      vanpoolId,
      employerName: employerData.employerName,
      employerId: employerData.employerId,
      selectedSubscriptionRate: summaryData.subscriptionRate,
      subscriptionType: 'Fixed',
      startDate: action.startDate,
      homeLatitude: selectedVan.matchedOriginStop.latitude,
      homeLongitude: selectedVan.matchedOriginStop.longitude,
      workLatitude: selectedVan.matchedDestinationStop.latitude,
      workLongitude: selectedVan.matchedDestinationStop.longitude,
      packageDiscountType: state.joinCommute.drivingScheduleData.isDesiredDriverRole ? 'driver' : 'rider',
      isFrequentDriver: state.joinCommute.drivingScheduleData.isDesiredDriverRole ? state.joinCommute.drivingScheduleData.isFrequentDriver : null,
      packageName: commuteData.package,
      rosterDays: commuteData.rosterDays,
      paymentMethods: cards,
      driverCoordInformation,
      agreementId: contractInfo.agreementId ? contractInfo.agreementId : null,
      clickwrapId: contractInfo.clickwrapId ? contractInfo.clickwrapId : null
    }
    if(inviteId){
      request.inviteId = inviteId;
    }
    try {
      const subscriptionURL = `${config.kongApiBaseUrl}/vanpools/vanpool/subscription`;
      let response = yield call(() =>
        Http.post(subscriptionURL, request, { vanpoolId })
      );

      if (response.success) {
        yield put({
          type: SAVE_SUBSCRIPTION_SUMMARY_SUCCESS,
        });
          localStorageService.clear('inviteId');
        action.history.push('/JoinCommute/onboarding');
      } else {
        yield put({
          type: SAVE_SUBSCRIPTION_SUMMARY_FAILURE,
        });
      }
    } catch (ex) {
      logger.log(ex);
      yield put({
        type: SAVE_SUBSCRIPTION_SUMMARY_FAILURE,
      });
    }
  }
}

function* getVanpoolInfo(action) {
  const state = yield select();
  const vanpoolId = state && state.joinCommute && state.joinCommute.selectedVan && state.joinCommute.selectedVan.vanpoolId;
  // const vanpoolId = "07VP-006045";
  if (vanpoolId) {
    try {
      const vanpoolURL = `${config.kongApiBaseUrl}/vanpools/vanpool`;
      let response = yield call(() =>
        Http.get(vanpoolURL, { vanpoolId })
      );
      if (response && response.vanpoolId) {
        yield put({
          type: GET_VANPOOL_INFO_SUCESS,
          data: response
        });
      } else {
        if (action && action.history) {
          action.history.push('/JoinCommute/error');
        }
      }
    } catch (ex) {
      logger.log(ex);
      if (action && action.history) {
        action.history.push('/JoinCommute/error');
      }
    }
  }
}


function* getVanpoolParticipants(action) {
  const state = yield select();
  const vanpoolId = state && state.joinCommute && state.joinCommute.selectedVan && state.joinCommute.selectedVan.vanpoolId;
  // const vanpoolId = "07VP-006045";
  if (vanpoolId) {
    try {
      const participantURL = `${config.kongApiBaseUrl}/vanpools/vanpool/participants`;
      let response = yield call(() =>
        Http.get(participantURL, { vanpoolId })
      );
      if (response && response.vanpoolId) {
        yield put({
          type: GET_VANPOOL_PARTICIPANTS_SUCESS,
          data: response
        });
      } else {
        if (action && action.history) {
          action.history.push('/JoinCommute/error');
        }
      }
    } catch (ex) {
      logger.log(ex);
      if (action && action.history) {
        action.history.push('/JoinCommute/error');
      }
    }
  }
}

function* uploadPicture(action) {
  try {
    const profileURL = `${config.kongApiBaseUrl}/user/profile/image`;
    const result = action.data.split(',')[1];
    const request = {
      imageData: result
    }
    let response = yield call(() =>
      Http.put(profileURL, request)
    );
    if (response && response.success) {
      yield put({
        type: UPLOAD_PROFILE_PICTURE_SUCCESS,
        data: action.data
      });
      yield put({
        type: USER_PROFILE_LOADING,
      });

    } else {
      yield put({
        type: UPLOAD_PROFILE_PICTURE_FAILURE,
      });
    }
  } catch (ex) {
    logger.log(ex);
    yield put({
      type: UPLOAD_PROFILE_PICTURE_FAILURE,
    });
  }
}

function* applyToSignContract(action) {
  const userInfo = userService.getAuthentication();
  const contractUrl = `${config.kongApiBaseUrl}/user/participant/agreement`;
  const vanpoolId = action.data.vanpoolId;
  var paylaod = {
    "profileId": userInfo.profileId,
    "vanpoolId": action.data.vanpoolId,
    "isDriver": action.data.isDriver
  }
  try {
    const response = yield call(() => Http.post(contractUrl, paylaod, { vanpoolId }));
    if (response) {
      if (response.agreementURL) {
        response.isPendingApplication = action.data.isPendingApplication;
        yield put({ type: SAVE_CONTRACT_DETAILS, data: response });
        if (!action.data.isPendingApplication) {
          action.data.history.push({
            pathname: '/agreement',
            state: {
              navigateTo: action.data.navigateTo,
            },
          })
        }
      }
      if (response.agreementId && response.isAgreed) {
        var data = { agreementId: response.agreementId, clickwrapId: response.clickwrapId ? response.clickwrapId : null, isAgreed: response.isAgreed, agreementURL: response.agreementURL, fromBroucher: false, isPendingApplication: action.data.isPendingApplication };
        yield put({ type: SAVE_CONTRACT_DETAILS, data: data });
        if (!action.data.isPendingApplication) {
          action.data.history.push(action.data.navigateTo);
        }
      }
    }

  } catch (ex) {
    logger.log(ex);
    yield put({ type: LOADER_ACTIVE, data: false });
    if (action && action.data.history) {
      action.data.history.push(action.data.navigateTo);
    }
  }
}

function* privateVanpoolSearch(action) {
  var searchDetails = JSON.parse(action.data.vanpoolInfo);
  const [homeAddress, workAddress] = yield all([
    call(() => MapService.getAddressByLatLang(parseFloat(searchDetails.homeLatitude), parseFloat(searchDetails.homeLongitude))),
    call(() => MapService.getAddressByLatLang(parseFloat(searchDetails.workLatitude), parseFloat(searchDetails.workLongitude)))
  ]);
  searchDetails.homeAddress = homeAddress;
  searchDetails.workAddress = workAddress;
  var employerId = action.data.employerId;
  searchDetails.employerId = employerId;
  var tempObj = {
    isDesiredDriverRole: searchDetails.isDesiredDriverRole ? true : false,
    role: searchDetails.isDesiredDriverRole ? 0 : 2,
    isFrequentDriver: searchDetails.isFrequentDriver ? searchDetails.isFrequentDriver : null
  };
  var employerData = {
    employerId: employerId,
    employerName: null
  }
  var activeDays = Object.values(searchDetails.rosterDays).filter(val => val == true);
  searchDetails.package = getUserPackage(activeDays.length);
  yield put({ type: UPDATE_USER_COMMUTE_DATA, data: searchDetails, updateOldPlan: true });
  yield put({ type: SET_USER_DRIVING_SCHEDULE, data: tempObj });
  yield put({ type: SET_USER_EMPLOYER_DATA, data: employerData });
 // yield call(() => saveCommuteProfile(searchDetails));
  yield put({ type: GET_MATCHING_VANPOOLS, data: { privateVanpool: true } });
};

function* saveCommuteProfile(data) {
  const commuteUrl = `${config.kongApiBaseUrl}/user/profile/commuteProfile`;
  const profile = UserService.getUserProfile();
  const userInfo = UserService.getAuthentication();
  let payload = {
    referralChannel: 'Friend',
    phone: profile.phone,
    defaultTransportationModeId: 2,
    originLatitude: data.homeLatitude,
    originLongitude: data.homeLongitude,
    originArrivalTime: data.arriveTime,
    originDepartureTime: data.departTime,
    originAddress: data.homeAddress ? data.homeAddress : null,
    destinationAddress: data.workAddress ? data.workAddress : null,
    destinationArrivalTime: data.arriveTime,
    destinationLongitude: data.workLongitude,
    destinationLatitude: data.workLatitude,
    destinationDepartureTime: data.departTime,
    employerName: data.employerName ? data.employerName : null,
    profileId: userInfo.profileId,
    isAllowNotifyCommuteMatch: false,
    isWorkDepartTimeFlexible: data.flexArrivalMinutes
      ? data.flexArrivalMinutes
      : false,
    worksiteId: null,
    employerId: data.employerId,
    commuteDays: getActiveDaysFromRoosterDays(data.rosterDays),
    isWorkArriveTimeFlexible: data.flexDepartureMinutes
      ? data.flexDepartureMinutes
      : false
  };
  try {
    yield call(() => Http.put(commuteUrl, payload));
  } catch (ex) {
    logger.log(ex);
  }
}

export default function* () {
  yield takeLatest(GET_EMPLOYERS_LIST, getEmployersList);
  yield takeLatest(GET_MATCHING_VANPOOLS, findVanpoolMatches);
  yield takeLatest(SET_USER_INFO, saveUserInfo);
  yield takeLatest(GET_BIN_NUMBERS, getBINNumbers);
  yield takeLatest(VERIFY_CARD, verifyPayment);
  yield takeLatest(GET_PRORATED_AMOUNT, getProratedAmount);
  yield takeLatest(SAVE_SUBSCRIPTION_SUMMARY, saveSummary);
  yield takeLatest(GET_VANPOOL_INFO, getVanpoolInfo);
  yield takeLatest(GET_VANPOOL_PARTICIPANTS, getVanpoolParticipants);
  yield takeLatest(UPLOAD_PROFILE_PICTURE, uploadPicture);
  yield takeLatest(APPLY_TO_SIGN_CONTRACT, applyToSignContract);
  yield takeLatest(PRIVATE_VANPOOL_SEARCH, privateVanpoolSearch);
  yield takeLatest(INVITE_VANPOOL_SEARCH, inviteVanpoolMatches);
}
