import PriceComponent from '../common/price-component/PriceComponent';
import SponsoredVanpool from '../helpers/sponsoredVanpool';
import { useEffect, useState } from 'react';
import PriceDetails from './choose-plan/PriceDetails';

export default function Recommended(props) {

  const [taxes, updateTaxes] = useState([]);
  const [surcharges, updateSurcharges] = useState([]);
  const [surchargeAmount, updateSurchargeAmount] = useState();
  const [taxAmount, updateTaxamount] = useState();
  const [charge, updateCharge] = useState();
  const [isDriver, updateRole] = useState(false);
  const [total, updateTotal] = useState({ "price": 0, "decimal": 0 });


  useEffect(() => {
    if (props.selectedType === 0) {
      updateSurchargeAmount(props.driverPrice.surchargeAmount);
      updateTaxamount(props.driverPrice.taxAmount);
      updateSurcharges(props.driverPrice.surcharges);
      updateTaxes(props.driverPrice.taxes);
      updateCharge(props.driverPrice.rate);
      updateRole(true);
      updateTotal({
        "price": props.driverPrice.price,
        "decimal": props.driverPrice.decimal
      })
    }
    if (props.selectedType === 1) {
      updateSurchargeAmount(props.riderPrice.surchargeAmount);
      updateTaxamount(props.riderPrice.taxAmount);
      updateSurcharges(props.riderPrice.surcharges);
      updateTaxes(props.riderPrice.taxes);
      updateCharge(props.riderPrice.rate);
      updateRole(false);
      updateTotal({
        "price": props.riderPrice.price,
        "decimal": props.riderPrice.decimal
      })
    }

  }, [props.driverPrice, props.riderPrice, props.selectedType])

  return (
    <div className='recommended' role='group'>
      <div className='tag'>
        <div className='text'>Recommended</div>
      </div>
      {props.noOfDays > 4
        ?
        <div className='select-heading'>Unlimited Plan</div>
        :
        <div className='select-heading'>{props.noOfDays}-Day Plan</div>
      }
      <div className='comment2'>
        <ul>
          <li>Guaranteed seat on your specified day</li>
        </ul>
      </div>
      {props.selectedVan.isSubsidized && <SponsoredVanpool />}
      {props.isDriver && !props.wasRider
        ?
        (props.inviteId ?
          <div className='prices-container'>
            <PriceComponent
              small={true}
              charge={props.riderPrice} heading='Rider Plan' selected={props.selectedOption === 0 && props.selectedType === 1} onSelect={() => props.updateSelection(0, 1)} />
            <PriceComponent small={true} heading='Driver rate' charge={props.driverPrice} selected={props.selectedOption === 0 && props.selectedType === 0} onSelect={() => props.updateSelection(0, 0)} />
          </div>
          :
          <div className='prices-container'>
            <PriceComponent small={false} charge={props.driverPrice} selected={props.selectedOption === 0 && props.selectedType === 0} onSelect={() => props.updateSelection(0, 0)} />
          </div>
        )
        :
        <div className='prices-container'>
          <PriceComponent
            small={true}
            charge={props.riderPrice} heading='Rider Plan' selected={props.selectedOption === 0 && props.selectedType === 1} onSelect={() => props.updateSelection(0, 1)} />
          <PriceComponent small={true} charge={props.driverPrice} heading='Willing to drive?' selected={props.selectedOption === 0 && props.selectedType === 0} onSelect={() => props.updateSelection(0, 0)} />
        </div>
      }
      <div className='condition'>*All prices include fuel, maintenance, roadside assistance, insurance, applicable taxes and fees</div>
      {
        props.selectedType === 0 && props.selectedOption === 0 &&
        <div className='condition'>You can adjust your preferred driving days and frequency when you become an approved driver</div>
      }
      {
        (props.selectedOption === 0 && (props.selectedType === 1 || props.selectedType === 0)) ?
          <div className='price-charges'>
            <PriceDetails isDesiredDriverRole={isDriver} charge={charge} rateMessage={"Due to fluctuations in fuel prices, your rate can change to cover the additional costs."} taxTotal={parseFloat(surchargeAmount) + parseFloat(taxAmount)} taxes={taxes} surcharges={surcharges} total={total} />
          </div>
          :
          null
      }
    </div>);
}