import { useEffect, useRef, useState } from 'react';
import Badge from '../../../_styles/images/question-icon.svg';
import CloseIcon from '../../../_styles/images/close-icon.svg';

export default function TooltipLink(props) {

  const [open, updateOpen] = useState(false);

  const node = useRef();

  const clickOutside = (e) => {
    if (node.current.contains(e.target)) {
      return;
    }
    updateOpen(false)
  }

  useEffect(() => {
    document.addEventListener('mousedown', clickOutside);
    return () => {
      document.removeEventListener('mousedown', clickOutside);
    }
  }, [open])

  function showInfo(event) {
    if (event.key === 'Enter') {
      updateOpen(!open);
    }
  }

  return (
    <div className='tooltip-link-container' role='group' aria-labelledby="tooltip-link-label">
      <div
        className={`info ${open ? 'open' : ''}`}
        onClick={e => {
          if (
            e.target.className == 'closeIcon' ||
            e.currentTarget.className == 'info '
          ) {
            updateOpen(!open);
          }
        }}
        role='button'
        tabIndex={0}
        onKeyDown={(event) => {
          if (event.key === 'Enter' || event.key === ' ') {
            event.preventDefault();
            updateOpen(!open);
          }
        }}
        aria-expanded={open}
        ref={node}
        aria-label={props.linkText}
      >
        <img src={Badge} alt="tooltip icon" aria-hidden='true' />
        {open && (
          <div
            className="message"
            role="alert"
            aria-live="polite"
            tabIndex={-1}
            ref={(tooltipNode) => open && tooltipNode?.focus()}
          >
            <img
              className="closeIcon"
              tabIndex={0}
              role="button"
              src={CloseIcon}
              alt="close icon"
              onClick={() => updateOpen(false)}
              onKeyDown={(event) => {
                if (event.key === 'Enter' || event.key === ' ') {
                  event.preventDefault();
                  updateOpen(false);
                }
              }}
            />
            {props.message}
          </div>
        )}
      </div>
      <div className='link-text'
        onClick={e => {
          if (
            e.target.className == 'closeIcon' ||
            e.currentTarget.className == 'info ' ||
            e.target.className == 'link-text'
          ) {
            updateOpen(!open);
          }
        }}
        tabIndex={0}
        role="link"
        onKeyDown={(event) => {
          if (event.key === 'Enter' || event.key === ' ') {
            event.preventDefault();
            updateOpen(!open);
          }
        }}>
        {props.linkText}
      </div>
    </div >
  );
}