import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { LINK_VANPOOL, AUTHORIZATION_REQUEST } from '../redux/actionTypes';
import Loading from '../shared/loading/Loading';
import FancyInput from '../shared/FancyInput';
import Callable from '../shared/Callable';
import FieldInfo from '../shared/FieldInfo';
import ConfirmationPopup from '../shared/popup/ConfirmationPopup';
import logoutService from '../login/logoutService';
import azureService from '../login/azureService';
import analyticsService from '../shared/analytics/analytics-service';

const message = (
  <div>
    Your vanpool ID and coordinator can be found in your Commute Welcome email.{' '}
    Otherwise, please contact your coordinator or call{' '}
    <Callable phone="800-VAN-4-WORK" /> to retrieve your vanpool ID.
  </div>
);

const domainMismatchMessage = (
  <div>
    The vanpool ID entered is associated with a Commute with Enterprise partner.{' '}
    For the best Commute experience, please sign up with your valid work email. 
    If you have questions, please call <Callable phone="800-VAN-4-WORK" />.
  </div>
);

const emailFound = email => (
  <div>
    You already have a Commute account associated to this vanpool with the
    following e-mail:
    <div className="email">{email}</div>
    Please return to login and use the above email address or call{' '}
    <Callable phone="800-VAN-4-WORK" /> if you require assistance.
  </div>
);

export class InVanpool extends Component {
  constructor(props) {
    super(props);

    this.state = {
      vanpoolId: '',
      coordinatorName: '',
      loading: false,
      failed: false
    };
  }

  componentWillReceiveProps(props) {
    if (props.vanpoolLinkSuccess === this.props.vanpoolLinkSuccess) {
      return;
    }

    this.setState({
      failed: props.vanpoolLinkSuccess === false,
      loading: this.state.vanpoolId && props.vanpoolLinkSuccess === null
    });

    if (props.vanpoolLinkSuccess && !props.domainMismatch) {
      this.reauthorize();
    }
  }

  reauthorize() {
    this.props.reauthorize();
    this.setState({ loading: true });
  }

  submit() {
    const { vanpoolId, coordinatorName } = this.state;
    analyticsService.analyticsProcessEvent({
      "event": "click_generic",
      "context": {
          "event_action": "next"
      }
    });
    this.props.submit({ vanpoolId, coordinatorName });
    this.setState({ loading: true, failed: false });
  }

  valid() {
    return (
      /^[a-z0-9]{4}-\d{6}$/i.test(this.state.vanpoolId) &&
      this.state.coordinatorName
    );
  }

  setVanpoolId(id) {
    if (/^[a-z0-9]{0,4}$/i.test(id.substr(0, 4)) && id.length < 5) {
      return this.setState({ vanpoolId: id });
    }

    if (/^[a-z0-9]{0,4}-$/i.test(id.substr(0, 5)) && id.length === 5) {
      return this.setState({ vanpoolId: id });
    }

    if (/^[a-z0-9]{0,4}-\d{1,6}$/i.test(id)) {
      return this.setState({ vanpoolId: id });
    }
  }

  render() {
    const { loading, vanpoolId, coordinatorName, failed } = this.state;
    const { forceLinking, domainMismatch, existingEmail, isFederatedLogin } = this.props;

    return (
      <div>
        <ConfirmationPopup
          title="Welcome Commute Vanpool Partner"
          message={domainMismatchMessage}
          active={!!domainMismatch && !loading}
          cancel={() => this.reauthorize()}
          cancelMessage="Continue Anyway"
          confirm={() => azureService.changeEmail()}
          confirmMessage="Sign up with Work Email"
        />
        <ConfirmationPopup
          title="Existing Email Found"
          message={emailFound(existingEmail)}
          active={!!existingEmail && !loading}
          confirm={() => logoutService.logout()}
          confirmMessage="Return to Log In"
        />
        <div className="in-vanpool">
          <Loading isLoading={loading} />
          <div className="title">
            Vanpool ID
            <FieldInfo message={message} />
          </div>
          <div className="desc">
            Please enter your vanpool ID and the first four letters of your
            coordinator’s last name.
            <span>
              Vanpool ID must Include the dash as it appears in your e-mail.
            </span>
          </div>
          {failed ? (
            <div className="desc error">
              We are unable to match your information with an active vanpool.
              Please try again. If you don't have this information contact
              Commute with Enterprise at <Callable phone="800-VAN-4-WORK" />.
            </div>
          ) : null}
          <FancyInput
            type="text"
            placeholder="12VP-008675"
            label="Vanpool ID"
            value={vanpoolId}
            onChange={e => this.setVanpoolId(e.target.value)}
          />
          <FancyInput
            type="text"
            placeholder="XXXX"
            label="Coordinator's Last Name"
            value={coordinatorName}
            onChange={e =>
              this.setState({ coordinatorName: e.target.value.substr(0, 4) })
            }
          />
          <div className="actions">
            {forceLinking ? null : 
              isFederatedLogin ?
              <Link to="/registration/commute-user-contact-details" className="button">
                Back
              </Link>
              :
              <Link to="/registration" className="button">
                Back
              </Link>
            }
            <button
              className="primary button"
              disabled={!this.valid()}
              onClick={() => this.submit()}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return { ...state.registration, forceLinking: state.userAuth.linkVanpool, isFederatedLogin: state.userAuth.isFederatedLogin };
}

function mapDispatchToProps(dispatch) {
  return {
    submit(data) {
      return dispatch({ type: LINK_VANPOOL, data });
    },
    reauthorize() {
      return dispatch({ type: AUTHORIZATION_REQUEST });
    }
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InVanpool);
