import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import * as actionTypes from '../../redux/actionTypes';
import Loading from '../../shared/loading/Loading';
import dateUtility from '../../shared/dateUtility';
import config from '../../appConfig';
import Http from '../../shared/http-service';
import ChevronRight from '../../_styles/images/ChevronRight.png';
import ArrowLeft from '../../_styles/images/ArrowLeft.png';
import { saveAs } from 'file-saver';
import { Document, Page, pdfjs } from 'react-pdf/dist/esm/entry.webpack';
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import InvoiceDetailCollapsible from '../../payment/paymentOverview/collapsibles/InvoiceDetailCollapsible';
import { isEqual } from 'lodash';
import useError from '../../shared/components/error-screen/useError';
pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;
import analyticsService from '../../shared/analytics/analytics-service';

function Invoices(props) {
    const [loading, updateLoading] = useState(false);
    const [pdfLoading, updatePdfLoading] = useState(false);
    const [invoices, updateInvoices] = useState([]);
    const [selectedInvoice, updateSelectedInvoice] = useState({});
    const [pdfFile, updatePdfFile] = useState(null);
    const [numPages, setNumPages] = useState(null);

    useEffect(() => {
        if (props.invoiceHistory.length) {
            var invoices = extract(props.invoiceHistory);
            const sortedInvoices = Object.keys(invoices).sort().reverse().map(key => ({ key: key, invoices: invoices[key] }));
            if (sortedInvoices.length) {
                var firstInvoice = sortedInvoices[0].invoices[0];
                updateSelectedInvoice(firstInvoice)
                updateInvoices(sortedInvoices);
            }
            updateLoading(false);
        }
    }, [props.invoiceHistory])

    useEffect(() => {
        if (!props.isInvoiceSummaryLoaded || !props.isSubscriptionSummaryLoaded) {
            updateLoading(true);
            props.init();
        }
        analyticsService.pageInfo("invoices");
    }, [])

    useEffect(() => {
        if (props.isInvoiceSummaryLoaded && props.isSubscriptionSummaryLoaded) {
            updateLoading(false);
        }
    }, [props.isInvoiceSummaryLoaded, props.isSubscriptionSummaryLoaded])

    useEffect(() => {
        if (selectedInvoice && selectedInvoice.invoiceId) {
            updatePdfLoading(true);
            downloadAndSaveInvoice(selectedInvoice.invoiceId, false, selectedInvoice);
        }
    }, [selectedInvoice])

    function extract(invoices) {
        var groups = {};

        invoices.forEach(function (val) {
            var date = val.invoiceYear
            if (date in groups) {
                groups[date].push(val);
            } else {
                groups[date] = new Array(val);
            }
        });
        return groups;
    }

    function selectInvoice(invoice) {
        updateSelectedInvoice(invoice);
        props.chooseInvoice(invoice);
        let invoiceMonth = (invoice.invoiceMonth).toLowerCase();
        let invoiceYear = invoice.invoiceYear
        analyticsService.analyticsProcessEvent({
            "event": "click_generic",
            "context": {
              "event_action": `${(invoice.invoiceMonth).toLowerCase()} ${invoice.invoiceYear}`
            }
          });
    }

    function goToInvoicePage(invoice) {
        updateSelectedInvoice(invoice);
        props.chooseInvoice(invoice);
        props.history.push('/payment/summary/invoices/details');
    }

    async function downloadAndSaveInvoice(invoiceId, download, invoice) {
        let response = await Http.getStatic(
            `${config.kongApiBaseUrl}/vanpools/vanpool/invoices/invoice/pdf/download/${invoiceId}`, { "Content-Type": "application/pdf" });
        if (response.ok) {
            const pdf = await response.blob();
            if (!download) {
                const file = window.URL.createObjectURL(pdf);
                updatePdfFile(file)
                updatePdfLoading(false);
            } else {
                saveAs(pdf, `${invoiceId}.pdf`);
                analyticsService.analyticsProcessEvent({
                    "event": "download",
                    "context": {
                      "event_action": "download",
                      "file_name": `${invoice.invoiceMonth}${invoice.invoiceYear}invoice.pdf`
                    }
                  });
            }

        } else {
            props.showError();
        }

    }

    function onDocumentLoadSuccess({ numPages: nextNumPages }) {
        setNumPages(nextNumPages);
    }

    useEffect(() => {
        if (!isEqual(props.chosenInvoice, {})) {
            updateSelectedInvoice(props.chosenInvoice);
        }
    }, [props.chosenInvoice])

    useError(props.invoiceSummaryError, props.history, props.invoiceSummaryErrorString);

    return (
        <div className='payment-invoice-page'>
            <Loading isLoading={loading} />
            <div className='container'>
                <div className='header'>
                    <div className='backArrow'>
                        <Link className="overview-link" onClick={props.history.goBack}>
                            <img src={ArrowLeft} alt='go back'></img>
                        </Link>

                    </div>
                    <div className='heading'>Invoices</div>
                </div>
                {invoices && invoices.length > 0 && <div className='download'>
                    <button className='download-button' aria-label={`download ${selectedInvoice.invoiceMonth} ${selectedInvoice.invoiceYear} invoice`} onClick={() => downloadAndSaveInvoice(selectedInvoice.invoiceId, true, selectedInvoice)} >Download</button>
                </div>}
                {
                    !invoices || invoices.length === 0 ?
                        <div className='no-records'>You don’t have any recent invoices</div>
                        :
                        <div className='main'>
                            <div className='leftSection desktopOnly'>
                                {
                                    invoices && invoices.length > 0 && invoices.map((group) => (
                                        <div key={group.key} aria-label={`${group.key} invoices list`}>
                                            <div className='year'>{group.key}</div>
                                            <div className='listContainer'>
                                                {
                                                    group.invoices.map((invoice) => (
                                                        <div key={invoice.invoiceDate} className={"invoice " + `${invoice.invoiceDate === selectedInvoice.invoiceDate ? "active" : ""}`} onClick={() => { selectInvoice(invoice) }} tabIndex={0} role='button' aria-selected={invoice.invoiceDate === selectedInvoice.invoiceDate} aria-controls='invoiceDraw'>
                                                            <div className='invoiceMonth'>{invoice.invoiceMonth + " " + invoice.invoiceYear}</div>
                                                            <div className='caret'> {invoice.invoiceDate === selectedInvoice.invoiceDate ?
                                                                <img src={ChevronRight} aria-hidden='true'></img>
                                                                : ""}</div>
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                            <div className='leftSection mobileOnly'>
                                {
                                    invoices && invoices.map((group) => (
                                        <div key={group.key} aria-label={`${group.key} invoices list`}>
                                            <div className='year'>{group.key}</div>
                                            <div className='listContainer'>
                                                {
                                                    group.invoices.map((invoice) => (
                                                        <div key={invoice.invoiceDate} className="invoice " onClick={() => { goToInvoicePage(invoice) }} tabIndex={0} role='button' aria-selected={invoice.invoiceDate === selectedInvoice.invoiceDate} aria-controls='invoiceDraw'>
                                                            <div className='invoiceMonth'>{invoice.invoiceMonth + " " + invoice.invoiceYear}</div>
                                                            <div className='caret'>
                                                                <img src={ChevronRight} aria-hidden='true'></img>
                                                            </div>
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                            {invoices && invoices.length > 0 && <div className='rightSection desktopOnly'>
                                {
                                   // props.invoiceSummary && <div className='invoice-month-details'>{`${dateUtility.monthNameFromIndex(props.invoiceSummary.invoiceMonth)} Invoice balance due by ${dateUtility.parseDateAsMonthDayYear(props.invoiceSummary.invoiceDueDate)}`}</div>
                                   props.lastUpdatedDateTime && <div className='invoice-month-details' role='note'>{`Last updated ${dateUtility.parseDateAsMonthDayYearFromGMT(props.lastUpdatedDateTime)}`}</div>
                                }

                                {selectedInvoice && <InvoiceDetailCollapsible invoice={selectedInvoice} />}
                                <div className='invoice-view-container' id='invoiceDraw'>
                                    {pdfLoading ? <div className="pdf-loader"></div> :
                                        <Document file={pdfFile} onLoadSuccess={onDocumentLoadSuccess} >
                                            {Array.from(new Array(numPages), (el, index) => (
                                                <Page key={`page_${index + 1}`} pageNumber={index + 1} />
                                            ))}
                                        </Document>
                                    }
                                </div>
                            </div>}
                        </div>
                }

            </div>
        </div>
    );
}

function mapStateToProps(state) {
    return {
        invoiceHistory: state.invoiceHistory.invoiceSummary,
        invoiceSummary: state.PaymentOverview.subscriptionSummary.invoiceSummary,
        chosenInvoice: state.invoiceHistory.chosenInvoice,
        isInvoiceSummaryLoaded: state.invoiceHistory.summaryLoaded,
        isSubscriptionSummaryLoaded: state.PaymentOverview.summaryLoaded,
        invoiceSummaryError: state.invoiceHistory.invoiceSummaryError,
        invoiceSummaryErrorString: state.invoiceHistory.invoiceSummaryErrorString,
        lastUpdatedDateTime: state.PaymentOverview.subscriptionSummary.lastUpdatedDateTime
    }
}

function mapDispatchToProps(dispatch) {
    return {
        init() {
            dispatch({ type: actionTypes.INVOICE_HISTORY_DETAILS_LOADING });
            dispatch({ type: actionTypes.SUBSCRIPTION_SUMMARY_LOADING });
        },
        showError() {
            dispatch({ type: actionTypes.DISPLAY_ERROR, data: "Failed to load invoice" });
        },
        chooseInvoice(invoice) {
            dispatch({ type: actionTypes.CHOOSE_INVOICE, data: invoice });
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Invoices);
