import React, { useEffect, useRef, useState } from 'react';
import Close from '../../_styles/images/close-icon-green.svg';
import Alert from '../../_styles/images/AlertIcon.png';
import Callable from '../../shared/Callable';
import logoutService from '../../login/logoutService';
import analyticsService from '../../shared/analytics/analytics-service';


export default function LeaveVanpoolPopup(props) {
  const node = useRef();

  function updateCloseKeydown(e) {
    if (e.which === 13) {
      props.close()
    }
  }
  function updateStaykeydown(e) {
    if (e.which === 13) {
      props.leave()
    }
  }

  function onLeaveClick() {
    analyticsService.analyticsProcessEvent({
      "event": "modal_click_generic",
      "context": {
        "event_action": "leave",
        "event_detail": {
            "modal": "leave this vanpool modal"
            }
        }
    });
    props.leave();
  }

  const [seconds, setSeconds] = useState(9);

  function forNonCoordinator() {
    return (
      <div className="leave-vanpool-container">
        <div className='top-header'>
          <button
            role='button'
            className="close traditional"
            onClick={() => props.close()}
            onKeyDown={updateCloseKeydown}
            tabIndex={0}
            aria-label="Close edit driving schedule popup"
          >
            <img src={Close} alt='Close' />
          </button>
        </div>
        <div className="sp-header">
          <div className='header'>
            <div className='van-image'>
              <img src={Alert} alt='alert' />
            </div>
            <div className='heading'>
              Leave this vanpool?
            </div>
          </div>
        </div>
        <div className='leave-vanpool-popup-container'>
          <div className='content'>
            Once you leave, all data related to your vanpool (including scheduled payments) will be removed
          </div>
        </div>
        <div className='button-container'>
          <button tabIndex={0} className='leave-button' onClick={onLeaveClick} onKeyDown={updateStaykeydown}>Leave</button>
          <button tabIndex={0} className='continue-button' onClick={() => {props.close();
              analyticsService.analyticsProcessEvent({
                "event": "modal_click_generic",
                "context": {
                  "event_action": 'stay',
                  "event_detail": {
                      "modal": "leave this vanpool modal"
                      }
                  }
              })
            }} onKeyDown={updateCloseKeydown}>Stay</button>
        </div>
      </div>
    )
  }

  function forCoordinator() {
    return (
      <div className="leave-vanpool-container">
        <div className='top-header'>
          <button
            role='button'
            className="close traditional"
            onClick={() => props.close()}
            onKeyDown={updateCloseKeydown}
            tabIndex={0}
            aria-label="Close edit driving schedule popup"
          >
            <img src={Close} alt='Close' />
          </button>
        </div>
        <div className="sp-header">
          <div className='header'>
            <div className='van-image'>
              <img src={Alert} alt='alert' />
            </div>
            <div className='heading'>
              Leave this vanpool?
            </div>
          </div>
        </div>
        <div className='leave-vanpool-popup-container'>
          <div className='content coordinator'>
            Please call  <Callable className="greentext" phone="800 VAN-4-WORK" /> to transfer duties to leave van. Once you leave, all data related to your vanpool (including scheduled payments) will be removed.
          </div>
        </div>
        <div className='button-container'>
          <button tabIndex={0} className='leave-button' onClick={() => props.close()} onKeyDown={updateCloseKeydown}>Close</button>
          <button tabIndex={0} className='continue-button' onClick={() =>{ props.close();
          analyticsService.analyticsProcessEvent({
            "event": "modal_click_generic",
            "context": {
              "event_action": 'stay',
              "event_detail": {
                  "modal": "leave this vanpool modal"
                  }
              }
            })
          }} onKeyDown={updateCloseKeydown}>Stay</button>
        </div>
      </div>
    )
  }

  function updateTime() {
    if(seconds > 1){
      setSeconds(seconds => seconds - 1);
    }else {
      logoutService.logout();
    }
       
  };

  function showLeftVanpool() {
    const token = setTimeout(updateTime, 1000);
    return (
      <div className="leave-vanpool-container">
        <div className='top-header'>
          <button
            role='button'
            className="close traditional"
            onClick={() => {logoutService.logout()}}
            onKeyDown={updateCloseKeydown}
            tabIndex={0}
            aria-label="Close edit driving schedule popup"
          >
            <img src={Close} alt='Close' />
          </button>
        </div>
        <div className="sp-header">
          <div className='header'>
            <div className='van-image'>
              <img src={Alert} alt='alert' />
            </div>
            <div className='heading'>
              Successfully left vanpool
            </div>
          </div>
        </div>
        <div className='leave-vanpool-popup-container'>
          <div className='content coordinator'>
            You have been removed from the vanpool. You will be logged out in {seconds} seconds.
          </div>
        </div>
        <div className='button-container'>
          <button tabIndex={0} className='continue-button' onClick={() => {
          analyticsService.analyticsProcessEvent({
            "event": "modal_click_generic",
            "context": {
              "event_action": 'log out',
              "event_detail": {
                  "modal": "successfully left vanpool modal"
                  }
              }
            });
            logoutService.logout()}
            } onKeyDown={() => logoutService.logout()}>Log out</button>
        </div>
      </div>
    )
  }

  return (
    <div className="charges-popup show" id='leave-vanpool-popup' aria-live='polite' role='alert' aria-modal='true' ref={node}>
      <div className='leave-container'>

        {
          props.leftVanpool && !props.isCoordinator ?
          showLeftVanpool() :
            null

        }

        {
          !props.leftVanpool && props.isCoordinator ?
            forCoordinator() :
            !props.leftVanpool ?
              forNonCoordinator() : null

        }

      </div>
    </div>
  );
}
