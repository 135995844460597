import React, { useState } from 'react';
import ChevronUp from '../../_styles/images/new-icons/chevron-up.png';
import { numberWithCommas } from '../../join-commute/helpers/commonHelper';

const TaxesAccordion = ({ total, taxes, surcharges }) => {
  const [isOpen, setIsOpen] = useState(true);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className='taxes-accordion'>
      <button className={`accordion ${isOpen ? 'active' : ''}`} onClick={toggleAccordion} aria-expanded={isOpen} aria-controls="taxes-panel">
        <span className='description'><img src={ChevronUp} alt={isOpen ? 'Collapse Taxes and Fees' : 'Expand Taxes and Fees'} className={`caret ${isOpen ? '' : 'rotate'}`} />Taxes and Fees</span>
        <span className='total'><div className='dollar'>$</div>{numberWithCommas(parseFloat(total).toFixed(2))}</span>
      </button>
      <div className={isOpen ? 'panel active' : 'panel'} id="taxes-panel" role="region" aria-labelledby="taxes-button">
          {taxes && taxes.map((tax, index) => (
            <div key={index} className='tax-description' role='group'>
                <div className='description'>{tax.description}</div>
                <div className='amount'><div className='dollar'>$</div>{numberWithCommas(parseFloat(tax.amount).toFixed(2))}</div>
            </div>
          ))}
          {surcharges && surcharges.map((surcharge, index) => (
            <div key={index} className='surcharges-description' role='group'>
                <div className='description'>{surcharge.description}</div>
                <div className='amount'><div className='dollar'>$</div>{numberWithCommas(parseFloat(surcharge.amount).toFixed(2))}</div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default TaxesAccordion;
