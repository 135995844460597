import Collapsible from 'react-collapsible';
import { useState } from 'react';
import StopsContainer from './StopsContainer';


function RouteStops() {
    const [accordianOpen, setAccordianOpen] = useState(false)

    function heading() {
        let tempClass = 'arrow ';
        tempClass += accordianOpen ? 'up ' : 'down ';
        return (
            <div className='collapsible-heading-container' role='button' aria-controls='collapsible' aria-expanded={accordianOpen} tabIndex={0}>
                <h1 className='heading'>{"View Stops"}</h1>
                <i className={tempClass}></i>
            </div>
        );
    }

    return (
        <Collapsible aria-expanded={accordianOpen} open={accordianOpen} handleTriggerClick={() =>  setAccordianOpen(!accordianOpen)} trigger={heading()} >
            <StopsContainer customIcons={true} showPencil={false}/>
        </Collapsible>
    );
}

export default RouteStops;
