import milesaved from '../../_styles/images/milesaved.png';
import coSaved from '../../_styles/images/coSaved.png';
import costSavings from '../../_styles/images/costSavings.png';
import fuelSaved from '../../_styles/images/fuelSaved.png';
import currencyFormatter from '../../shared/currencyFormatter';
import LetUsHelpYouSection from './let-us-help-you/LetUsHelpYouSection';
import EditCommuteDays from './edit-commute-days/EditCommuteDays';
import { numberWithCommas } from '../helpers/commonHelper';
import SponsoredVanpool from '../helpers/sponsoredVanpool';

export default function PersonalSavings(props) {
  const { milesSaved, fuelGallonsSaved, co2PoundsSaved, fuelCostSavings, isSubscriptionVanpool, charge, isOptimalMatch, editCommute, isUpdated, selectedDays, updatePDPopup, updateTDPopup, isSubsidized, isIPB } = props;
  const fuelSavings = currencyFormatter.formatUSD(fuelCostSavings);

  return (
    <div className="vanpool-savings" role='group'  aria-labelledby="savings-header">
      <div id="savings-header" className="personal-savings">
        Personal Savings <span>per month</span>
      </div>
      <div className="savings">
        <ul role="list">
          <li>
            <div className="start" role="text" aria-labelledby="miles-saved-label">
              <div className="icon">
                <img src={milesaved} alt="Miles saved icon" />
              </div>
              <div id="miles-saved-label" className="description">
                Miles Saved
              </div>
            </div>
            <div className="end" aria-live="polite" aria-label={`${numberWithCommas(milesSaved)} miles saved`}>
              {numberWithCommas(milesSaved)} mi
            </div>
          </li>
          <li>
            <div className="start" role="text" aria-labelledby="fuel-saved-label">
              <div className="icon">
                <img src={fuelSaved} alt="Fuel saved icon" />
              </div>
              <div id="fuel-saved-label" className="description">
                Fuel Saved
              </div>
            </div>
            <div className="end" aria-live="polite" aria-label={`${numberWithCommas(fuelGallonsSaved)} gallons saved`}>
              {numberWithCommas(fuelGallonsSaved)} gal
            </div>
          </li>
          <li>
            <div className="start" role="text" aria-labelledby="co2-saved-label">
              <div className="icon">
                <img src={coSaved} alt="CO2 saved icon" />
              </div>
              <div id="co2-saved-label" className="description">
                CO2 Saved
              </div>
            </div>
            <div className="end" aria-live="polite" aria-label={`${numberWithCommas(co2PoundsSaved)} pounds saved`}>
              {numberWithCommas(co2PoundsSaved)} lbs
            </div>
          </li>
          <li>
            <div className="start" role="text" aria-labelledby="cost-savings-label">
              <div className="icon">
                <img src={costSavings} alt="Cost savings icon" />
              </div>
              <div id="cost-savings-label" className="description">
                Cost Savings
              </div>
            </div>
            <div className="end" aria-live="polite" aria-label={`$${fuelSavings} saved`}>
              {fuelSavings}
            </div>
          </li>
        </ul>
      </div>
      {(selectedDays.length || isUpdated) && <EditCommuteDays selectedDays={selectedDays} openEditCommuteModal={editCommute} />}
      <div className="booknow-container-mobile mobile">
        {isSubscriptionVanpool ? (
          <div className="booknow" role="region" aria-labelledby="subscription-info">
              <div id="subscription-info" className="disclaimer mobile" role="group">
                <p>
                Recurring subscription. <br />
                Rates subject to change. <br />
                <span onClick={updatePDPopup}>Learn More</span>
                </p>
            </div>
            <div className="total">Total</div>
            <div className="van-amount">
              <div className='price-group'>
                <div className="doller">{'$'}</div>
                <div className="price">{numberWithCommas(charge.price)}</div>
                <div className="decimal">.{charge.decimal}</div>
              </div>
              <div className="month">{'per month'}</div>
            </div>
          </div>
        ) : (

          isIPB ? 
          (
            <div className='booknow traditional-ibp'>
                <div className='ipb-top'>
                  <div className='traditional-label'> Contact for Pricing</div>
                </div>
                {isSubsidized && <SponsoredVanpool />}
            </div> 
          )
          :
           charge && (
                <div className="booknow traditional">
                  <div className='total-disclaimer'>
                    <div className="total">
                      Total
                      </div>
                    <div className='traditional-disclaimer'>
                      Estimate only. <span onClick={() => updateTDPopup(true)}> Learn More</span>
                    </div>
                  </div>
                <div className="van-amount" role='group'>
                  <div className='price-group'>
                    <div className="dollar">{'$'}</div>
                    <div className="price">{numberWithCommas(charge.price)}</div>
                    <div className="decimal">.{charge.decimal}</div>
                  </div>
                  <div className="month">{'per month'}</div>
                </div>
              </div>
              
              )
        
        
        )}
        {
          !isIPB && isSubsidized && <SponsoredVanpool />
        }
        {/* {isSubsidized && <SponsoredVanpool />} */}
        <span className={isUpdated ? 'booknow-help-container updated': 'booknow-help-container'}>
        {
          !isOptimalMatch &&
          <LetUsHelpYouSection isSelected={selectedDays.length !== 0} goToStayInTouch={props.goToStayInTouch} isMobile={true} isTraditional={!isSubscriptionVanpool} />
        }
        {!isOptimalMatch && <div className='divider'></div>}
        {
          !isSubscriptionVanpool ?
            <button onClick={props.openCloseModal} className="check-now-button" >continue</button>
            :
            (isOptimalMatch || (!isOptimalMatch && isUpdated)) ?
              <button onClick={() => props.openCloseModal("booknow")} className="book-now-button" >BOOK NOW</button> :
              <button onClick={editCommute} className={'edit-commute-button secondary'} >EDIT MY COMMUTE</button>

        }
        </span>
      </div>
      {
        isSubscriptionVanpool && 
        <div className='disclaimer tablet' role='group'>
          <p>
          Recurring subscription. 
          Rates subject to change.<span>&nbsp;</span>
          <span onClick={updatePDPopup}>Learn More</span>
          </p>
      </div>
      }

    </div>
  )
}

