import {useEffect, useState} from 'react';

import CardCheckBox from './CardCheckBox';
import CardInformation from '../../join-commute/payment-screen/card-information/CardInformation';
import Close from '../../_styles/images/close-icon-green.svg';
import FederalComponent from '../../join-commute/payment-screen/federal-component/FederalComponent';
import creditCardService from '../../shared/credit-card/creditCardService';
import {initDate} from '../../join-commute/helpers/commonHelper';
import analyticsService from '../../shared/analytics/analytics-service';
import LegalCopy from './LegalCopy';
import { TAB_KEY_CODE } from '../../shared/helpers/accessibilityHelpers';

function AddCard(props) {
  const [cardNumber, updateCardNumber] = useState('');
  const [cardValid, updateCardValid] = useState(true);
  const [expirationDate, updateExpirationDate] = useState(initDate);
  const [cvv, updateCvv] = useState('');
  const [cvvValid, updateCvvValid] = useState(true);
  const [zip, updateZip] = useState('');
  const [zipValid, updateZipValid] = useState(true);
  const [dateValid, updateDateValid] = useState(true);
  const [cardType, updateCardType] = useState('');
  const [buttonDisabled, updateButtonDisabled] = useState(true);
  const [isFederal, updateIsFederal] = useState(null);
  const [showFederalComponent, updateShowFederalComponent] = useState(null);
  const [isCvvDisabled, updateCvvDisabled] = useState(true);
  const [checked, updateChecked] = useState(false);
  const [showSetDefault, updateShowSetDefault] = useState(false);
  const [showSetBackup, updateShowSetBackup] = useState(false);
  const [disableCheckbox, updateDisableCheckbox] = useState(false);
  const [showCheckbox, updateShowCheckbox] = useState(false);
  const [authorizedAmount, updateAuthorizedAmount] = useState();
  const [isAmountValid, updateIsAmountValid] = useState(true);
  useEffect(() => {
    analyticsService.getPageName({ 'pagename': 'payment/add new card'});
    return () => {
      updateCardNumber('');
      updateCardValid(true);
      updateExpirationDate(initDate);
      updateCvv('');
      updateCvvValid(true);
      updateZip('');
      updateZipValid(true);
      updateDateValid(true);
      updateCardType('');
      updateButtonDisabled(true);
      updateIsFederal(null);
      updateCvvDisabled(true);
      updateChecked(false);
      updateShowSetDefault(false);
      updateShowSetBackup(false);
      updateDisableCheckbox(false);
      updateShowCheckbox(false);
      updateAuthorizedAmount();
      updateIsAmountValid(true);
    };
  }, []);

  function onDateChange(type, value) {
    updateExpirationDate({
      ...expirationDate,
      [type]: value
    });
  }

  function toggleCheckBox() {
    if(!checked){
      analyticsService.analyticsProcessEvent({
        "event": "click_generic",
        "context": {
          "event_action": "make default card"
        }
      });
    }
    updateChecked(!checked);
  }

  useEffect(() => {
    updateShowCheckbox(false);
    updateShowSetDefault(false);
    updateDisableCheckbox(false);
    updateChecked(false);
    updateShowSetBackup(false);
    if (props.cardDetails) {
      //delete card scenario
      if(props.isDeleteScenario){
        updateShowCheckbox(true);
        updateShowSetDefault(true);
        updateDisableCheckbox(true);
        updateChecked(true);
      } else {
        //Add card scenario
        // checking if no cards on file
        if (props.cardDetails.federal === 0 && props.cardDetails.retail === 0) {
          updateShowCheckbox(true);
          updateShowSetDefault(true);
          updateDisableCheckbox(true);
          updateChecked(true);
        } else if (props.cardDetails.primaryCards === 0) {
          updateShowCheckbox(true);
          updateShowSetDefault(true);
        }
        else {
          // if there are some cards
          if (isFederal !== null) {
            if (isFederal === true) {
              if (props.cardDetails.retail > 0) {
                updateShowCheckbox(true);
                updateShowSetDefault(true);
                if (props.cardDetails.federal === 0) {
                  updateDisableCheckbox(true);
                  updateChecked(true);
                }
              } else if (props.cardDetails.federal > 0) {
                updateShowCheckbox(true);
                updateShowSetDefault(true);
              }
            }
            if (isFederal === false) {
              // If I am adding a retail card
              if (props.cardDetails.primaryCards && props.cardDetails.primaryCards[0] && props.cardDetails.primaryCards[0].isFederalBenefitCard) {
                // if There is a federal card
                updateShowCheckbox(true);
                updateShowSetBackup(true);
              } else {
                // if there is no federal card
                updateShowCheckbox(true);
                updateShowSetDefault(true);
              }
            }
          }
        }
      }
    }
  }, [props.cardDetails, isFederal, cardNumber]);

  useEffect(() => {
    if (isFederal === true && (props.cardDetails.federal === 0 || (props.cardDetails.federal !== 0 && showSetDefault && checked))) {
      updateShowFederalComponent(true);
    } else {
      updateShowFederalComponent(false);
    }
  }, [isFederal, showSetDefault, checked, props.cardDetails]);

  useEffect(() => {
    const today = new Date();
    if (expirationDate.month && expirationDate.year && expirationDate.month < today.getMonth() + 1 && expirationDate.year <= today.getFullYear()) {
      updateDateValid(false);
    } else {
      updateDateValid(true);
    }
  }, [expirationDate]);

  function checkCVV(value) {
    const type = creditCardService.getCardTypeJoinCommute(cardNumber);
    if (creditCardService.isCvcValid(type.cvcLength, value)) {
      updateCvv(value);
    }
  }

  function checkZip(value) {
    if (/^\d{0,5}$/.test(value)) {
      updateZip(value);
      if (value.length === 5) {
        updateZipValid(true);
      } else {
        updateZipValid(false);
      }
    }
  }

  function checkCardNumber(value) {
    if (value.length >= 7) {
      const isFederalCard = creditCardService.checkIfFederal(value, props.binNumbers);
      updateIsFederal(isFederalCard);
    } else {
      updateIsFederal(false);
    }
    value = value.split(' ').join('');
    if (creditCardService.isNumber(value)) {
      const type = creditCardService.getCardTypeJoinCommute(value);
      updateCardType(type);
      value = value.substr(0, Math.max.apply(Math, type.length));
      updateCardNumber(value);
    }
  }

  function checkCardInfoValid(cardNumber, cardType, cvv) {
    const isCVVValid = cardType && cvv && cvv.length === cardType.cvcLength;
    if (cvv) {
      updateCvvValid(isCVVValid);
    }
    const isCardNumberValid = cardType && cardNumber && cardType.length.includes(cardNumber.length);
    const isLunh = creditCardService.luhn(cardNumber);
    if (cardNumber && cardType) {
      updateCardValid(isCardNumberValid && cardType.type !== '' && isLunh);
    } else {
      updateCardValid(true);
    }
    return !!isCVVValid && isCardNumberValid && isLunh;
  }

  useEffect(() => {
    const isValid = checkCardInfoValid(cardNumber, cardType, cvv, zip);
    const federalValidation = isFederal ? ((showCheckbox && showSetDefault && !checked) || (showCheckbox && showSetDefault && checked && !!authorizedAmount && isAmountValid)) : true;
    if (cardNumber && cardType && cvv && zip && expirationDate && isValid && federalValidation && zipValid && dateValid) {
      updateButtonDisabled(false);
    } else {
      updateButtonDisabled(true);
    }
  }, [cardNumber, cardType, cvv, zip, expirationDate, isFederal, authorizedAmount, zipValid, dateValid, isAmountValid, checked, showCheckbox, showSetDefault]);

  useEffect(() => {
    if (!cardValid || cardNumber === '') {
      checkCVV('');
      updateCvvDisabled(true);
    } else {
      updateCvvDisabled(false);
    }
  }, [cardValid, cardNumber]);

  function onContinue() {
    props.addCard({
      cardNumber,
      cardType,
      cvv,
      expirationDate,
      zip,
      isFederal,
      isBackup: showSetBackup && checked,
      isDefault: showSetDefault && checked,
      maxAuthorizedAmount: authorizedAmount
    });
    analyticsService.analyticsProcessEvent({
      "event": "click_generic",
      "context": {
        "event_action": "continue"
      }
    });
  }

  useEffect(() => {
    if (props.reference.current) {
      props.reference.current.focus();
    }
  }, []);

  const keyDownHandler = (e) => {
    if (e.keyCode === TAB_KEY_CODE) {
      e.preventDefault();
      const firstFocusElement = document.getElementById('close-icon');
      firstFocusElement?.focus();
    } else {
      onContinue();
    }
  }

  return (
    <div className='card-operations-container' ref={props.reference} aria-modal='true' role='dialog' aria-describedby='heading'>
      <div className='header'>
        <div className='heading' id='heading'>Add payment</div>
        <img id='close-icon' className='close' role='button' src={Close} onKeyDown={props.close} tabIndex={0} onClick={props.close} alt='close icon'/>
      </div>
      <div className='sub-heading'>Card Information</div>
      <CardInformation
        ref={props.reference}
        cardType={cardType}
        cardValid={cardValid}
        cardNumber={cardNumber}
        expirationDate={expirationDate}
        checkCardNumber={checkCardNumber}
        onDateChange={onDateChange}
        cvv={cvv}
        checkCVV={checkCVV}
        zip={zip}
        checkZip={checkZip}
        cvvValid={cvvValid}
        isCvvDisabled={isCvvDisabled}
        zipValid={zipValid}
        dateValid={dateValid} 
        focus={true}
        />
      {showCheckbox && <CardCheckBox
        checked={checked}
        toggleCheckBox={toggleCheckBox}
        setDefault={showSetDefault}
        setBackup={showSetBackup}
        disabled={disableCheckbox}
      />}
      {isFederal && showFederalComponent && <FederalComponent
        authorizedAmount={authorizedAmount}
        onUpdateAuthorizedAmount={updateAuthorizedAmount}
        updateIsAmountValid={updateIsAmountValid}
        maxAmount={props.maxAmount}
        isAmountValid={isAmountValid} />}
      <LegalCopy  isFederal={isFederal}/>
      <button id='continue-button' disabled={buttonDisabled} className='continue-button' onClick={onContinue} onKeyDown={keyDownHandler}>continue</button>
    </div>
  );
}

export default AddCard;
