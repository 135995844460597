import { connect } from 'react-redux';
import Savings from './savings';
import { useState, useEffect } from 'react';
import * as actionTypes from '../../redux/actionTypes';
import { PERMISSION_VANPOOL_STATUS_RENTAL, PERMISSION_VAN_STARTING_IN_FUTURE } from '../../shared/permissions/permissions';
import { isAllowed } from '../../shared/permissions/RestrictTo';
import { ENTER_KEY_CODE } from '../../shared/helpers/accessibilityHelpers';
import InfoIcon from '../../_styles/images/infoIcon.png';


function EnvironmentDetails(props) {
  const { vanpoolImpactData, personalImpactData, impactDataLoaded, loadImpactData, vanpoolStatus } = props;
  const [activeTab, setActiveTab] = useState('personal');
  const [impactData, setImpactData] = useState({});
  const [isAnyValueZero, setIsAnyValueZero] = useState(false);

  useEffect(() => {
    if (!impactDataLoaded) {
      loadImpactData();
    }
  }, []);

  useEffect(() => {
    setImpactData(activeTab === 'personal' ? personalImpactData : vanpoolImpactData);
  }, [activeTab, personalImpactData, vanpoolImpactData]);

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const handleKeyDown = (e, tab) => {
    if (e.which === ENTER_KEY_CODE) {
      handleTabChange(tab);
    }
  };

  useEffect(() => {
    Object.keys(impactData).every((key) => {
      if(impactData[key] === 0){
        setIsAnyValueZero(true);
        return false;
      } else {
        return true;
      }
    })
  }, [impactData])


  return (
    <div className="environment-information" role='group'>
      <div className='heading'>
        Savings <span>per month</span>
      </div>
      <div className="tabs">
        <div
          className={`tab ${activeTab === 'personal' ? 'active' : ''}`}
          onClick={() => handleTabChange('personal')}
          onKeyDown={(e) => handleKeyDown(e, 'personal')}
          role="tab"
          tabIndex="0"
          aria-selected={activeTab === 'personal'}
          aria-label={`${activeTab === 'personal' ? 'Personal savings tab active' : 'Personal savings tab'}`}
        >
          Personal
        </div>
        <div
          className={`tab ${activeTab === 'vanpool' ? 'active' : ''}`}
          onClick={() => handleTabChange('vanpool')}
          onKeyDown={(e) => handleKeyDown(e, 'vanpool')}
          role="tab"
          tabIndex="0"
          aria-selected={activeTab === 'vanpool'}
          aria-label={`${activeTab === 'vanpool' ? 'Vanpool savings tab active' : 'Vanpool savings tab'}`}
        >
          Vanpool
        </div>
      </div>
      {((vanpoolStatus === 'P' || vanpoolStatus === 'A' || isAllowed([PERMISSION_VAN_STARTING_IN_FUTURE])) && (isAnyValueZero)) && <div className='notification-container'>
        <div className='info-icon' aria-hidden={true}>
          <img className='tick-icon' src={InfoIcon} alt="Information icon" />
        </div>
        <div className='notification-text'>Once your vanpool starts, watch your savings grow</div>
      </div>}
      <Savings  {...impactData} />
    </div>
  );
}
function mapStateToProps(state) {
  return {
    vanpoolImpactData: state.impactData.vanpool.current,
    personalImpactData: state.impactData.participant.current,
    isImpactDataLoaded: state.impactData.impactDataLoaded
  }
}

function mapDispatchToProps(dispatch) {
  return {
    loadImpactData() {
      if (isAllowed([PERMISSION_VANPOOL_STATUS_RENTAL]) && !isAllowed([PERMISSION_VAN_STARTING_IN_FUTURE])) {
        dispatch({ type: actionTypes.VANPOOLER_IMPACT_DATA_LOADING })
      }
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EnvironmentDetails);
