import { APPLY_TO_SIGN_CONTRACT, LOADER_ACTIVE, UPDATE_COMMUTE_CHARGE, UPDATE_START_DATE } from '../../../redux/actionTypes';
import Calendar, { MONTHS } from '../../../shared/inputs/Calendar/Calendar';
import { addDays, getDaysFromRosterDays, numberWithCommas } from '../../helpers/commonHelper';
import { RouteDetails } from '../../common/route-details/RouteDetails';
import { isEqual } from 'lodash';
import { initialJoinCommuteState } from '../../JoinCommuteReducer';
import { useEffect, useRef, useState } from 'react';
import Loading from '../../../shared/loading/Loading';
import { connect } from 'react-redux';
import ImageNotAvailable from '../../../_styles/images/ImageNotAvailable.svg';
import analyticsService from '../../../shared/analytics/analytics-service';
import PriceDisclaimerPopup from '../PriceDisclaimerPopup';
import TaxesAccordion from '../../../payment/common/TaxesAccordion';
import RateAccordion from '../../../payment/common/RateAccordion';
import dateUtility from '../../../shared/dateUtility';
import SponsoredVanpool from '../../helpers/sponsoredVanpool';

const rateMessage = "Due to fluctuations in fuel prices, your rate can change to cover the additional costs.";

function Summary(props) {

  const node = useRef(null);

  const [selectedVan, updateSelectedvan] = useState({});
  const [rideDays, updateRideDays] = useState([]);
  const [isOpen, updateIsOpen] = useState(false);
  const [selectedDay, updateSelectedDay] = useState();
  const [buttonDisabled, updateButtonDisabled] = useState(true);
  const [enabledDays, updateEnabledDays] = useState([]);
  const [startDay, updateStartDay] = useState(new Date(new Date().setHours(0, 0, 0, 0)));
  const [endDay, updateEndDay] = useState(new Date(new Date().setHours(0, 0, 0, 0)));
  const [showPDPopup, updatePDPopup] = useState(false);

  useEffect(() => {
    if (isEqual(props.joinCommuteData, initialJoinCommuteState)) {
      props.history.push("/JoinCommute");
    }
    if (props.joinCommuteData && props.joinCommuteData.userCommuteData && props.joinCommuteData.userCommuteData.fromBroucher) {
      continueFlowFromBroucher()
    }
  }, [])

  useEffect(() => {
    analyticsService.pageInfo("choose subscription");
  });

  function checkAndCloseCalendar() {
    const modal = document.getElementById("modal");
    const classes = Array.from(modal.classList);
    if (classes.includes('show')) {
      const all = document.querySelector(".vanpool-summary");
      all.setAttribute("aria-hidden", "false");
      updateIsOpen(false);
    }
  }

  function toggleCalendar() {
    if (!isOpen) {

      const modal = document.getElementById("modal");
      // Hide the background page with ARIA
      const all = document.querySelector(".vanpool-summary");
      all.setAttribute("aria-hidden", "true");

      // Add the classes and attributes to make the modal visible
      updateIsOpen(true);
      modal.setAttribute("aria-modal", "true");
      modal.querySelector(".calendar-day").focus();

      analyticsService.analyticsProcessEvent({
        "event": "click_generic",
        "context": {
          "event_action": "select start date"
        }
      });
      analyticsService.analyticsProcessEvent({
        "event": "start_date_modal_view",
        "context": {
          "event_action": "start date modal"
        }
      })
    } else {
      const all = document.querySelector(".vanpool-summary");
      all.setAttribute("aria-hidden", "false");
      updateIsOpen(false);
    }

  }

  function onCalendarKeydown(e) {
    if (e.which == 13) {
      toggleCalendar();
    }
  }

  useEffect(() => {
    if (props.joinCommuteData.selectedVan) {
      updateSelectedvan(props.joinCommuteData.selectedVan)
      updateButtonDisabled(!props.joinCommuteData.selectedVan.isPredelivery)
      var rosterDays = props.joinCommuteData.userCommuteData.rosterDays;
      var ridedays = Object.keys(rosterDays).filter((d) => rosterDays[d]);
      updateRideDays(ridedays);
      let startDay = new Date(new Date().setHours(0, 0, 0, 0));
      if (props.joinCommuteData.selectedVan.earliestStartDate && new Date(new Date(props.joinCommuteData.selectedVan.earliestStartDate).setHours(0, 0, 0, 0)) >= startDay) {
        startDay = new Date(new Date(props.joinCommuteData.selectedVan.earliestStartDate).setHours(0, 0, 0, 0));
      } else {
        startDay = addDays(3, startDay);
      }
      updateStartDay(startDay);
      if (props.joinCommuteData.selectedVan.latestSubscriptionStartDate && new Date(new Date(props.joinCommuteData.selectedVan.latestSubscriptionStartDate).setHours(0, 0, 0, 0)) >= startDay) {
        const day = new Date(new Date(props.joinCommuteData.selectedVan.latestSubscriptionStartDate).setHours(0, 0, 0, 0));
        updateEndDay(day);
      } else {
        const day = addDays(30, startDay);
        updateEndDay(day);
      }
    }
    if (props.joinCommuteData && props.joinCommuteData.userCommuteData && props.joinCommuteData.userCommuteData.startDate) {
      const convertedDate = new Date(props.joinCommuteData.userCommuteData.startDate);
      updateSelectedDay(convertedDate);
    }
  }, [props.joinCommuteData]);

  useEffect(() => {
    if (props.joinCommuteData.selectedVan && !props.joinCommuteData.selectedVan.isPredelivery) {
      if (selectedDay) {
        updateButtonDisabled(false);
      } else {
        updateButtonDisabled(true);
      }
    }
  }, [selectedDay, props.joinCommuteData])

  useEffect(() => {
    const days = getDaysFromRosterDays(rideDays);
    updateEnabledDays(days);
  }, [rideDays])

  function onContinue() {
    const startDateString = dateUtility.getDateStringFromDay(selectedDay);
    props.updateStartDate({ startDate: selectedDay, startDateString });
    props.updateCommuteCharge(selectedVan.charge.totalAmount);
    const isDriver = props.joinCommuteData.drivingScheduleData && props.joinCommuteData.drivingScheduleData.isDesiredDriverRole;
    const isCoordinator = props.joinCommuteData.isCoordinatorInvite;
    analyticsService.analyticsProcessEvent({
      "event": "subscription_summary_continue",
      "context": {
        "event_action": "continue"
      }
    });
    if (isDriver) {
      props.history.push('/JoinCommute/license/enter-license');
    } else {
      if (isCoordinator) {
        props.history.push('/JoinCommute/apply/coordinator');
      } else {
        props.history.push('/JoinCommute/verify/home-address');
        // props.updateLoading(true);
        // props.getDocusignContract(false, selectedVan.vanpoolId, props.history, '/JoinCommute/add-payment');
      }
    }
  }

  function continueFlowFromBroucher() {
    const startDateString = dateUtility.getDateStringFromDay(props?.joinCommuteData?.userCommuteData?.startDate);
    props.updateStartDate({ startDate: props.joinCommuteData.userCommuteData.startDate, startDateString });
    props.updateCommuteCharge(props.joinCommuteData.userCommuteData.charge);
    const isDriver = props.joinCommuteData.drivingScheduleData.isDesiredDriverRole;
    if (isDriver) {
      props.history.push('/JoinCommute/license/enter-license')
    } else {
      // props.updateLoading(true);
      // props.getDocusignContract(false, vanpoolId, props.history, '/JoinCommute/verify/home-address');
      props.history.push('/JoinCommute/verify/home-address');
    }
  }

  const clickOutside = (e) => {
    if (node.current.contains(e.target)) {
      return;
    }
    toggleCalendar();
  }

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', clickOutside);
      return () => {
        document.removeEventListener('mousedown', clickOutside);
      }
    }
  }, [isOpen])

  useEffect(() => {
    const close = (e) => {
      if (e.keyCode === 27) {
        checkAndCloseCalendar()
      }
    }
    window.addEventListener('keydown', close)
    return () => window.removeEventListener('keydown', close)
  }, []);

  function heading() {
    var tempClass = 'arrow ';
    tempClass += isCollapsibleOpen ? 'up ' : 'down ';
    return (
      <i className={tempClass}></i>
    );
  }

  return (
    <div className='vanpool-summary-container'>
      <Loading isLoading={props.isLoading} />
      <div className="vanpool-summary-page">
        <div className='heading'>Summary</div>
        <div className="vanpool-summary">
          <div className="vanpool-summary-details">
            <div className="vanpool-Details">
              <div className="van-details">
                <div className="van-class-details" role='group'>
                  <div className="van-class">{selectedVan.vehicleClass}</div>
                  <div className="van-model">{selectedVan.ymms}</div>
                </div>
                {selectedVan.charge && <div className="van-price-details" role='group'>
                  <div className='price-group' aria-label={`${selectedVan.charge.price} dollars and ${selectedVan.charge.decimal} cents`}>
                    <div className="doller">{'$'}</div>
                    <div className="price">{numberWithCommas(selectedVan.charge.price)}</div>
                    <div className="decimal">.{selectedVan.charge.decimal}</div>
                  </div>
                  <div className="sub">{"total per month"}</div>
                </div>}
              </div>
              <RouteDetails vanpool={selectedVan} />
              <section className="rideDays" aria-labelledby="rideDaysLabel">
                <span className='screenreader-only' id="rideDaysLabel">Your Ride Days</span>
                <ul>
                  {rideDays.length > 0 ? (
                    rideDays.map((day) => (
                      <li key={day} aria-label={day}>
                        {day.substr(0, 3)}
                      </li>
                    ))
                  ) : (
                    <li aria-label="No ride days available">No ride days available</li>
                  )}
                </ul>
              </section>
            </div>
          </div>
          <div className="vanpool-summary-image">
            <div className="van-Image">
              <div className={`${selectedVan.isPredelivery ? 'car-image-opacity' : 'car-image'}`}>
                <img src={selectedVan.carClassImage} onError={e => {
                  e.currentTarget.src = ImageNotAvailable;
                  e.currentTarget.className = "image-not-found";
                }} alt='van image' />
              </div>
            </div>
          </div>
          <div className="vanpool-summary-prices" role='group'>
            {selectedVan.isSubsidized && <div className="summary-sponsored-container">
              <span>What’s Included?</span>
              <SponsoredVanpool />
            </div>}
            <RateAccordion
              isDesiredDriverRole={props.joinCommuteData.drivingScheduleData?.isDesiredDriverRole || false}
              rateMessage={"Due to fluctuations in fuel prices, your rate can change to cover the additional costs."}
              charge={selectedVan?.charge?.rate || 0}
            />
            <TaxesAccordion
              taxes={props.taxes}
              surcharges={props.surcharges}
              total={(parseFloat(props.taxAmount) || 0) + (parseFloat(props.surchargeAmount) || 0)}
            />
            <div className='bottom' role="region">
              <div className='disclaimer'>
                <p>
                  Recurring subscription. <br />
                  Rates subject to change. <br />
                  <span role='button' onClick={() => updatePDPopup(true)} aria-label="Learn more about recurring subscriptions and rate changes">Learn More</span>
                </p>
              </div>
              <div className='total' role='group'>
                <div className='label'>Total</div>
                {selectedVan.charge &&
                  <span className='grp'>
                    <div className='price' aria-label={`${selectedVan.charge.price} dollars and ${selectedVan.charge.decimal} cents total per month`}>
                      <div className='dollar'>$</div>
                      <div>{numberWithCommas(selectedVan.charge.price)}</div>
                      <div className='dollar'>.{selectedVan.charge.decimal}</div>
                    </div>
                    <div className='frequency'>per month</div>
                  </span>
                }
              </div>
            </div>
          </div>
          {!selectedVan.isPredelivery && <div className="vanpool-summary-plan">
            <div className="van-datePicker" role='region' aria-labelledby="plan-heading">
              <h2 id="plan-heading" className="screenreader-only">Vanpool Plan Summary</h2>
              <div className="plan-heading">
                {props.joinCommuteData.plan.noOfDays === 5 ? "Unlimited Plan" : `${props.joinCommuteData.plan.noOfDays}-Day Plan`}
              </div>
              <div className='picker-container'>
                <label htmlFor="start-date-picker">Your Start Date*</label>
                <button id="start-date-picker" className='picker' onClick={toggleCalendar} role='button' onKeyDown={onCalendarKeydown} aria-expanded={isOpen} aria-haspopup="dialog">
                  {selectedDay ?
                    <div className='date-display'>
                      <div className='day'>{selectedDay.getDate()}</div>
                      <div className='month'>{MONTHS[selectedDay.getMonth()]}</div>
                      <div className='year'>{selectedDay.getFullYear()}</div>
                    </div>
                    :
                    <div className='placeholder'>
                      <span className='text'>Select Start Date</span>
                    </div>
                  }
                  <i className="time-arrow down"  aria-hidden="true"></i>
                </button>
              </div>
            </div>
          </div>}
        </div>
        <div aria-live='polite' role='alert' id='modal' className={"popup" + (isOpen ? " show" : " hide")}>
          <Calendar
            reference={node}
            buttonText={'confirm start date'}
            toggle={toggleCalendar}
            enabledDays={enabledDays}
            selectedDay={selectedDay}
            onConfirm={updateSelectedDay}
            startDay={startDay}
            endDay={endDay}
            reset={isOpen}
          />
        </div>
        {
          showPDPopup && <PriceDisclaimerPopup updatePDPopup={() => updatePDPopup(false)} />
        }
        <div className='button-container'>
          <button className='continue-button' onClick={onContinue} disabled={buttonDisabled}>CONTINUE</button>
        </div>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    joinCommuteData: state?.joinCommute,
    isLoading: state?.joinCommute?.loading,
    taxes: state?.joinCommute?.selectedVan?.charge?.taxes,
    surcharges: state?.joinCommute?.selectedVan?.charge?.surcharges,
    totalAmount: state?.joinCommute?.selectedVan?.totalAmount,
    surchargeAmount: state?.joinCommute?.selectedVan?.charge?.surchargeAmount,
    taxAmount: state?.joinCommute?.selectedVan?.charge?.tax,
    selectedStartDate: state?.joinCommute?.userCommuteData?.startDate,
  }
}
function mapDispatchToProps(dispatch) {
  return {
    updateStartDate(date) {
      dispatch({ type: UPDATE_START_DATE, data: date });
    },
    updateCommuteCharge(charge) {
      dispatch({ type: UPDATE_COMMUTE_CHARGE, data: charge });
    },
    getDocusignContract(isDriver, vanpoolId, history, navigateTo) {
      dispatch({ type: APPLY_TO_SIGN_CONTRACT, data: { isDriver: isDriver, vanpoolId: vanpoolId, history, navigateTo } })
    },
    updateLoading(data) {
      dispatch({ type: LOADER_ACTIVE, data });
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Summary)