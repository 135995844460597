import * as actionTypes from '../redux/actionTypes';

import React, { useEffect, useState } from 'react';

import ContactInfo from './ContactInfo';
import DashboardBackground from './new-dashboard/dashboard-background/DashboardBackground';
import DashboardVanDetails from './new-dashboard/van-details/DashboardVanDetails';
import DownloadApp from './download-app/DownloadApp';
import FuelCard from './FuelCard';
import ImpactDataContainer from '../vanpool-vehicle/impact-data/ImpactDataContainer';
import InviteRiderPopup from '../shared/popup/InviteRiderPopup';
import Loading from '../shared/loading/Loading';
import Maintenance from '../vanpool-vehicle/Maintenance';
import NewRegisteredUser from './registered-user/new-registered-user/NewRegisteredUser';
import { PERMISSION_ACCOUNT_DASHBOARD_ADMIN, PERMISSION_VAN_STARTING_IN_FUTURE } from '../shared/permissions/permissions';
import { Redirect } from 'react-router/cjs/react-router.min';
import { RestrictFrom } from '../shared/permissions/RestrictTo';
import RiderTripRecordingBanner from './RiderTripRecordingBanner';
import RouteMap from '../map/RouteMap';
import StopTracker from './StopTracker';
import TripRecordingBanner from './TripRecordingBanner';
import { connect } from 'react-redux';
import dateUtility from '../shared/dateUtility';
import {getNextRoutePoint } from '../shared/route-service';
import NotificationBanner from './notificationBanners/NotificationBanners';
import analyticsService from '../shared/analytics/analytics-service';
import { getDateForDashboard } from '../shared/helpers/dateHelpers';

function NewDashboard(props) {
  const [navigateTo, updateNavigateTo] = useState('');
  const [showRoute, updateShowRoute] = useState('outboundRoutePoints');
  const [showMap, updateShowMap] = useState(true);
  const [inviting, upateInviting] = useState(false);
  const [isNewSubscription, updateIsNewSubscription] = useState(false);
  const [pendingChangeStartDate, updatePendingChangeStartDate] = useState('');
  const [predeliveryStartDate, updatePredeliveryStartDate] = useState('');
  const [fname, updateFname] = useState('');
  const [isInVanpool, updateIsInVanpool] = useState(false);
  const [nextStop, updateNextStop] = useState(false);
  const [stop, updateStop] = useState(false);
  const [loading, updateLoading] = useState(true);
  const [vanpoolDetails, updateVanpoolDetails] = useState({});
  const [isVanForming, updateIsVanForming] = useState(false);
  const [isDriver, updateIsDriver] = useState(false);
  const [userCommuteDays, updateUserCommuteDays] = useState();
  useEffect(() => {
    props.setDirections([]);
    if (props.refreshDashboard) {
      updateLoading(true);
      props.fullRefresh();
    } else if (!props.isDashboardLoaded || !props.profileLoaded) {
      updateLoading(true);
      props.init();
    }
  }, []);

  useEffect(() => {
    if (props.isDashboardLoaded && props.profileLoaded) {
      updateLoading(false);
    } else {
      updateLoading(true);
    }
  }, [props.isDashboardLoaded, props.profileLoaded]);

  useEffect(() => {
    if (props.refreshDashboard === false) {
      updateLoading(false);
    }
  }, [props.refreshDashboard])

  useEffect(() => {
    if (props.isTraditionalLead) {
      props.history.push('/JoinCommute/vanpool-details/traditional-thankyou');
    }
  }, [props.isTraditionalLead])
  

  useEffect(() => {
    if (props.dashboard) {
      updateVanpoolDetails(props.dashboard.vanpoolDetails);
      if ( props.vanpoolStatus && (props.vanpoolStatus === 'A' || props.vanpoolStatus === 'P')) {
        updateShowMap(false);
        updateIsInVanpool(true);
        if(props?.dashboard?.vanpoolDetails?.expectedCommuteStartDate){
          const startDay = getDateForDashboard(props.dashboard.vanpoolDetails.expectedCommuteStartDate);
          updatePredeliveryStartDate(startDay);
          updateIsVanForming(false);
        } else {
          updateIsVanForming(true);
        }
      }
      else if (props.profile.subscriptionAPIInfo && props.profile.subscriptionAPIInfo.package === null && props.profile.subscriptionAPIInfo.pendingChange !== null) {
        updateIsNewSubscription(true);
        updateShowMap(false);
        const startDay = dateUtility.parseDateAsMonthDayYearFromGMTShort(props.profile.subscriptionAPIInfo.pendingChange.startDate);
        updatePendingChangeStartDate(startDay);
        updateIsInVanpool(true);
      }
      else if (!props.dashboard.vanpoolDetails || !props.dashboard.vanpoolDetails.vanpoolId || props.dashboard.vanpoolDetails.vanpoolId === null) {
        updateIsInVanpool(false);
        updateShowMap(false);
      }
      else if (props.dashboard.vanpoolDetails && props.dashboard.vanpoolDetails.vanpoolId && props.dashboard.vanpoolDetails.vanpoolId !== null) {
        updateIsInVanpool(true);
        updateShowMap(true);
      }
      else {
        updateShowMap(true);
      }
    }
    if (props.profile) {
      updateIsDriver(props.profile.isDriver);
      updateUserCommuteDays(props.profile.vanpool && props.profile.vanpool.smallCommuteDays);
    }
  }, [props.dashboard, props.profile, props.vanpoolRoute])

  useEffect(() => {
    if (props.vanpoolRoute) {
      const { route, index } = getNextRoutePoint(props.vanpoolRoute);
      updateShowRoute(route);
      updateNextStop(index);
      props.setDirections(props.vanpoolRoute[route]);
    }
  }, [props.vanpoolRoute])

  useEffect(() => {
    <Redirect push to={navigateTo} />;
  }, [navigateTo])

  useEffect(() => {
    const stopTemp = nextStop > -1 ? props.vanpoolRoute[showRoute][nextStop] : {};
    updateStop(stopTemp);
  }, [nextStop])

  useEffect(() => {
    if (props.profile) {
      updateFname(props.profile.fname);
    }
  }, [props.profile])

  useEffect(() => {
    if(isVanForming === true) {
      analyticsService.getPageName({
        "pagename": "dashboard/vanpool forming"
      })
    } else if(pendingChangeStartDate) {
      analyticsService.getPageName({
        "pagename": "dashboard/vanpool starts soon"
      })
    } else if(isInVanpool) {
      analyticsService.getPageName({
        "pagename": "dashboard/next commute"
      });
      analyticsService.BannerInfo("next commute");
    } else if(!isInVanpool && !isVanForming && !pendingChangeStartDate && !props.vanpoolId ) {
      analyticsService.getPageName({
        "pagename": "dashboard/not part of vanpool"
      });
    }
  }, [isVanForming, pendingChangeStartDate, isInVanpool]);

  const predeliveryText = "If a representative hasn't touched base with you yet, rest assured, one will reach out soon to keep you posted on the vanpool's progress.";
  const recentLeadSubmitted = "A representative will contact you within 24 business hours to get your commute set up!";

  return (
    loading ?
      <Loading isLoading={loading} />
      :
      <div className="dashboard">
        {showMap ?
          <div>
            <StopTracker
              stop={stop}
              index={nextStop}
              isDriver={isDriver}
              userCommuteDays={userCommuteDays}
              profile={props.profile}
              vanpoolRoute={props.vanpoolRoute}
              updateLoading={updateLoading}
              isSubscriptionUser={props.isSubscriptionUser}
            />
            <div className="map-spacer" onClick={() => updateNavigateTo('/route')} role='group'>
              <RouteMap
                controls={false}
                padding={
                  window.innerWidth > 768
                    ? { bottom: 125, top: -100, left: 0, right: 0 }
                    : null
                }
              />
            </div>
          </div>
          :
          <DashboardBackground name={fname} startDay={pendingChangeStartDate} predeliveryStartDate={predeliveryStartDate} isInVanpool={isInVanpool} isVanForming={isVanForming} isRecentLeadSubmitted={props.isRecentLeadSubmitted} />
        }
        <RestrictFrom roles={[PERMISSION_VAN_STARTING_IN_FUTURE]} required={false}>
          {isInVanpool && !isNewSubscription && !isVanForming && !predeliveryStartDate && <div className="trip-tools mobile-only" role='group'>
            <TripRecordingBanner />
            <RiderTripRecordingBanner />
          </div>}
        </RestrictFrom>
        <div className="bottom bottom-grid main-content">
          {isInVanpool ? <div className="left-side" role='group'>
            <RestrictFrom roles={[PERMISSION_VAN_STARTING_IN_FUTURE]} required={false}>
              {!isNewSubscription && !isVanForming && !predeliveryStartDate && <div className="trip-tools not-mobile" role='group'>
                <TripRecordingBanner />
                <RiderTripRecordingBanner />
              </div>}
            </RestrictFrom>
            {!isNewSubscription && !isVanForming && !predeliveryStartDate && <div className="vanpool-details solid" role='group'>
              <div
                className="maintenance-container"
                onClick={() => {props.history.push('/vanpool');
                                  analyticsService.analyticsProcessEvent({
                                    "event": "banner interaction",
                                      "context": {
                                        "event_action": "click", 
                                        "banner_topic": "preventive maintenance"
                                      }
                                  })}}
              >
                <Maintenance
                  nextMaintenanceDate={
                    vanpoolDetails.nextScheduledMaintenanceDate
                  }
                  nextMaintenanceMileage={
                    vanpoolDetails.nextScheduledMaintenanceOdometer
                  }
                  lastMaintenanceDate={
                    vanpoolDetails.lastPreventativeMaintenanceOdometerDate
                  }
                  lastMaintenanceMileage={
                    vanpoolDetails.lastPreventativeMaintenanceOdometer
                  }
                />
              </div>
            </div>}
            <NotificationBanner />
            {
              isVanForming && <div className='predelivery-text'>{predeliveryText}</div>
            }
            <DashboardVanDetails profile={props.profile} vanpoolDetails={props.dashboard.vanpoolDetails} isVanForming={isVanForming}/>
            {
              !isVanForming && !predeliveryStartDate && 
              <RestrictFrom
                roles={[PERMISSION_ACCOUNT_DASHBOARD_ADMIN]}
                required={false}
              >
                <ImpactDataContainer isNewSubscription={isNewSubscription} includeParking={false}  includeAccountDashboardInfo={false} />
              </RestrictFrom>
            }
            <div className="ipad-horizontal">
              <FuelCard balance={props.dashboard && props.dashboard.fuelCardBalance} />
              <ContactInfo />
            </div>
          </div>
            :
            <div className="left-side" role='group'>
            {
              props.isRecentLeadSubmitted && <div className='recentLeadSubmitted-text'>{recentLeadSubmitted}</div>
            }
              <NewRegisteredUser isRecentLeadSubmitted={ props.isRecentLeadSubmitted} />
            </div>
          }
          <div className="right-side" role='group'>
            <DownloadApp  isRecentLeadSubmitted={ props.isRecentLeadSubmitted}/>
          </div>
          <InviteRiderPopup
            active={inviting}
            close={() => this.setState({ inviting: false })}
          />
        </div>
      </div>
  );
}

function mapStateToProps(state) {
  return {
    dashboard: state.dashboard,
    isDashboardLoaded: state.dashboard.isDashboardLoaded,
    rosterLoaded: state.roster.loaded,
    vanpoolRoute: state.vanpoolRoute,
    profile: state.profile,
    profileLoaded: state.profile.isLoaded,
    refreshDashboard: state.dashboard.refreshDashboard,
    isTraditionalLead: state.dashboard.isTraditionalLead,
    isRecentLeadSubmitted: state.userAuth.isRecentLeadSubmitted,
    vanpoolStatus: state.userAuth.vanpoolStatus,
    isSubscriptionUser: state.userAuth.isSubscription,
    vanpoolId: state.userAuth.vanpoolId,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    init() {
      dispatch({ type: actionTypes.AUTHORIZATION_REQUEST});
      dispatch({ type: actionTypes.DASHBOARD_LOADING });
      dispatch({ type: actionTypes.ROUTE_LOADING });
      dispatch({ type: actionTypes.USER_PROFILE_LOADING });
    },
    setDirections(directions) {
      dispatch({ type: actionTypes.MAP_DIRECTIONS, data: directions });
    },
    fullRefresh() {
      dispatch({ type: actionTypes.REFRESH_DASHBOARD });
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(NewDashboard)


