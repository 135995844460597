import React from 'react';
import { connect } from 'react-redux';
import * as actionTypes from '../redux/actionTypes';
import { isAllowed } from '../shared/permissions/RestrictTo';
import { PERMISSION_REGISTERED_USER } from '../shared/permissions/permissions';

export const VanpoolRouteSummary = ({ name, id, init, vanpoolInfoLoaded }) => {
  if (name === 'N/A' || id === 'N/A' || !vanpoolInfoLoaded) {
    init();
  }

  return (
    <div className="vanpool-route-summary-view">
      <h2 className="vanpool-summary-name">{name}</h2>
      <h3 className="vanpool-summary-id">{id}</h3>
    </div>
  );
};

export const mapStateToProps = state => {
  return {
    name: state.vanpoolInfo.vanpoolName,
    id: state.vanpoolInfo.vanpoolId,
    vanpoolInfoLoaded: state.vanpoolInfo.loaded
  };
};

export const mapDispatchToProps = dispatch => {
  return {
    init() {
      if(!isAllowed([PERMISSION_REGISTERED_USER])){
        dispatch({ type: actionTypes.VANPOOL_INFO_LOADING });
      }
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VanpoolRouteSummary);
