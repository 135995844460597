import { useEffect, useState } from 'react';
import creditCardService from '../../shared/credit-card/creditCardService';
import { VERIFY_CARD, GET_BIN_NUMBERS, CLEAR_CARDS, APPLY_TO_SIGN_CONTRACT, LOADER_ACTIVE, SAVE_COMMUTE_PROFILE } from '../../redux/actionTypes';
import { connect } from 'react-redux';
import FederalComponent from './federal-component/FederalComponent';
import CardInformation from './card-information/CardInformation';
import { MONTHS } from '../helpers/commonHelper';
import { getSaveCardAPIInfo } from '../helpers/vanHelpers';
import Loading from '../../shared/loading/Loading';
import analyticsService from '../../shared/analytics/analytics-service';
import Lock from '../../_styles/images/lock.png';
import config from '../../appConfig';
import SponsoredVanpool from '../helpers/sponsoredVanpool';

function PaymentScreen(props) {
  const [cardNumber, updateCardNumber] = useState('');
  const [maskedCardNumber, updateMaskedCardNumber] = useState('');
  const [cardValid, updateCardValid] = useState(true);
  const [expirationDate, updateExpirationDate] = useState({
    month: MONTHS[new Date().getMonth()],
    year: new Date().getFullYear()
  });
  const [cvv, updateCvv] = useState('');
  const [cvvValid, updateCvvValid] = useState(true);
  const [zip, updateZip] = useState('');
  const [zipValid, updateZipValid] = useState(true);
  const [dateValid, updateDateValid] = useState(true);
  const [cardType, updateCardType] = useState('');
  const [buttonDisabled, updateButtonDisabled] = useState(true);
  const [isFederal, updateIsFederal] = useState(false);
  const [authorizedAmount, updateAuthorizedAmount] = useState();
  const [isCvvDisabled, updateCvvDisabled] = useState(true);
  const [isAmountValid, updateIsAmountValid] = useState(true);
  const [commuteCharge, updateCommuteCharge] = useState(null);
  const [isLoading, updateIsLoading] = useState(false);


  function onDateChange(type, value) {
    updateExpirationDate({
      ...expirationDate,
      [type]: value
    })
  }

  useEffect(() => {
    const today = new Date();
    if (expirationDate.month && expirationDate.year && expirationDate.month < today.getMonth() + 1 && expirationDate.year <= today.getFullYear()) {
      updateDateValid(false)
    } else {
      updateDateValid(true);
    }
  }, [expirationDate])

  function checkCVV(value) {
    let type = creditCardService.getCardTypeJoinCommute(cardNumber);
    if (creditCardService.isCvcValid(type.cvcLength, value)) {
      updateCvv(value);
    }
  }

  function checkZip(value) {
    if (/^\d{0,5}$/.test(value)) {
      updateZip(value);
      if (value.length === 5) {
        updateZipValid(true);
      } else {
        updateZipValid(false);
      }
    }
  }

  function checkCardNumber(value) {
    if (value.length >= 7) {
      const isFederalCard = creditCardService.checkIfFederal(value, props.binNumbers);
      updateIsFederal(isFederalCard);
      analyticsService.analyticsProcessEvent({
        "event": "federal_benefit_card_detected",
        "context": {
          "event_action": "federal benefit card"
        }
      });
    } else {
      updateIsFederal(false);
    }
    value = value.split(' ').join('');
    if (creditCardService.isNumber(value)) {
      const type = creditCardService.getCardTypeJoinCommute(value);
      updateCardType(type);
      value = value.substr(0, Math.max.apply(Math, type.length));
      updateCardNumber(value);
    }
  }

  function onContinue() {
    let chargeAmount = 0;
    if (isFederal) {
      if (commuteCharge > authorizedAmount) {
        chargeAmount = authorizedAmount;
      } else {
        chargeAmount = commuteCharge;
      }
    } else {
      chargeAmount = commuteCharge;
    }
    const cardInfo = {
      cardNumber,
      cardType,
      cvv,
      expiry: expirationDate,
      zip,
      isFederal,
      isBackup: false,
      isDefault: true,
      maxAmount: authorizedAmount,
      chargeAmount
    }
    const saveCardInfo = getSaveCardAPIInfo(cardInfo);
    props.saveCard(saveCardInfo, cardInfo, props.history);
    analyticsService.analyticsProcessEvent({
      "event": "add_payment_click_continue",
      "context": {
        "event_action": "continue"
      }
    });
    if (isFederal) {
      if (authorizedAmount >= commuteCharge) {
        props.history.push('/JoinCommute/summary');
      } else {
        props.history.push('/JoinCommute/add-payment/backup');
      }
    } else {
      props.history.push('/JoinCommute/summary');
    }
  }

  function checkCardInfoValid(cardNumber, cardType, cvv) {
    const isCVVValid = cardType && cvv && cvv.length === cardType.cvcLength;
    if (cvv) {
      updateCvvValid(isCVVValid);
    }
    const isCardNumberValid = cardType && cardNumber && cardType.length.includes(cardNumber.length);
    const isLunh = creditCardService.luhn(cardNumber);
    if (cardNumber && cardType) {
      updateCardValid(isCardNumberValid && cardType.type !== '' && isLunh);
    } else {
      updateCardValid(true);
    }
    return isCVVValid && isCardNumberValid && isLunh;
  }

  useEffect(() => {
    const isValid = checkCardInfoValid(cardNumber, cardType, cvv, zip);
    const federalValidation = isFederal ? (!!authorizedAmount && isAmountValid) : true;
    if (cardNumber && cardType && cvv && zip && expirationDate && isValid && federalValidation && zipValid && dateValid) {
      updateButtonDisabled(false);
    } else {
      updateButtonDisabled(true);
    }
  }, [cardNumber, cardType, cvv, zip, expirationDate, isFederal, authorizedAmount, zipValid, dateValid, isAmountValid]);

  useEffect(() => {
    if(window && window._analytics && window._analytics.process !== "add payment"){
      analyticsService.pageInfo("add payment");
    }
  })

  function saveCommuteInfo() {
    if(props.selectedVan && props.joinCommuteData){
      const selectedVan = props.selectedVan;
      const payload = {
        vanpoolId: selectedVan.vanpoolId,
        employerName: props.joinCommuteData.employerData.employerName,
        employerId: props.joinCommuteData.employerData.employerId,
        destinationAddress: selectedVan.destinationAddress,
        originAddress: selectedVan.originAddress,
        matchedOriginStop: selectedVan.matchedOriginStop,
        matchedDestinationStop: selectedVan.matchedDestinationStop,
        flexArrivalMinutes: props.joinCommuteData.userCommuteData.flexArrivalMinutes,
        flexDepartureMinutes: props.joinCommuteData.userCommuteData.flexDepartureMinutes,
        rosterDays: props.joinCommuteData.userCommuteData.rosterDays
      };
      props.saveCommuteProfile(payload);
    }
  }
  
  useEffect(() => {
    if(!props.isBinLoaded){
      props.getBinNumbers();
    }
    if(props?.savedCards?.length > 0 && props.savedCards[0]?.maskedCreditCardNumber){
      const cardInfo = props.savedCards[0];
      updateCardNumber(cardInfo.cardNumber);
      updateMaskedCardNumber(cardInfo.maskedCreditCardNumber);
      updateCardType(cardInfo.cardType);
      updateIsFederal(cardInfo.isFederal);
    } else {
      props.clearCards();
    }
    if(props.isCardSaving){
      updateIsLoading(true);
      props.updateLoading(true);
    } else {
      updateIsLoading(false);
      props.updateLoading(false);
    }
    if (props.contractInfo.fromBroucher && !props.isDesiredDriverRole && !props.contractInfo.isAgreed) {
      var navigateTo = '/JoinCommute/add-payment'
      props.getDocusignContract(false, props.selectedVan.vanpoolId, props.history, navigateTo);
      updateIsLoading(true);
      props.updateLoading(true)
    }
  }, [])

  useEffect(() => {
    if(!props.isCardSaving){
      updateIsLoading(false);
      props.updateLoading(false);
    }
  }, [props.isCardSaving])

  useEffect(() => {
    if (!cardValid || cardNumber == '') {
      checkCVV('');
      updateCvvDisabled(true);
    } else {
      updateCvvDisabled(false);
    }
  }, [cardValid, cardNumber])

  useEffect(() => {
    updateCommuteCharge(props.commuteCharge);
  }, [props.commuteCharge])

  useEffect(() => {
    if (isFederal) {
      analyticsService.getPageName({
        'pagename': "payment/add federal benefit card",
        'process': "add payment"
      });
    }
  }, [isFederal])

  return (
    <div className='payment-page'>
      <Loading isLoading={isLoading} />
      <div className='payment-container' role='group'>
        <div className='heading'>Add payment</div>
        {props.selectedVan?.isSubsidized ? <div className='payment-info'>
          All or some of the subscription cost of this vanpool is covered by the sponsor, but we need you to provide a payment method to cover one-time charges or the remaining amount of your subscription cost. Your card will only be charged if necessary.
        </div> : <div className='comment'>Please add your payment information for a credit, debit, or federal benefit card</div>}
        {props.selectedVan?.isSubsidized && <SponsoredVanpool />}
        <div className='sub-heading'>Card Information</div>
        <CardInformation
          cardType={cardType}
          cardValid={cardValid}
          cardNumber={cardNumber}
          expirationDate={expirationDate}
          checkCardNumber={checkCardNumber}
          onDateChange={onDateChange}
          cvv={cvv}
          checkCVV={checkCVV}
          zip={zip}
          checkZip={checkZip}
          cvvValid={cvvValid}
          isCvvDisabled={isCvvDisabled}
          zipValid={zipValid}
          dateValid={dateValid}
          maskedCCNumber={maskedCardNumber}
          deleteMaskedNumber={() => {updateMaskedCardNumber(''); updateCardNumber('');}}/>

        {isFederal && <FederalComponent
          authorizedAmount={authorizedAmount}
          onUpdateAuthorizedAmount={updateAuthorizedAmount}
          updateIsAmountValid={updateIsAmountValid}
          maxAmount={props.maxAmount}
          isAmountValid={isAmountValid}
          isCvvZipValid={(!zipValid && !cvvValid)}
          findAVan={true} />}

        {<div className={`legal-copy ${(isFederal || !zipValid || !cvvValid)? 'federal' : ''}`}>
            <div className='lock-image'>
              <img src={Lock} aria-hidden='true' />
            </div>
            <div className='legal-copy-text'>
              Your information will be submitted over a secure connection. <br/>
              For additional information, please review our
              {' '}
              <a
                href={config.privacyPolicyLink}
                target="_blank"
                rel="noopener noreferrer"
                className='link'
              >
                Privacy Policy
              </a>
              {' '}and{' '}
              <a
                href={config.termsOfUseLink}
                target="_blank"
                rel="noopener noreferrer"
                className='link'
              >
                Terms of Use{' '}
              </a>
            </div>
          </div>}
        <button tabIndex={0} disabled={buttonDisabled} className='continue-button' onClick={onContinue}>continue</button>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    binNumbers: state.joinCommute.payment.binNumbers,
    commuteCharge: state.joinCommute.userCommuteData.charge,
    isDesiredDriverRole: state.joinCommute.drivingScheduleData.isDesiredDriverRole,
    selectedVan: state.joinCommute.selectedVan,
    contractInfo: state.joinCommute.contractInfo,
    maxAmount: state.joinCommute.payment.maxAmount,
    isBinLoaded: state.joinCommute.payment.isBinLoaded,
    joinCommuteData: state.joinCommute,
    savedCards: state.joinCommute.payment.cards,
    isCardSaving: state.joinCommute.payment.isCardSaving
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getBinNumbers() {
      dispatch({ type: GET_BIN_NUMBERS })
    },
    saveCard(saveInfo, cardInfo, history) {
      dispatch({ type: VERIFY_CARD, data: cardInfo, saveInfo, history })
    },
    clearCards() {
      dispatch({ type: CLEAR_CARDS })
    },
    getDocusignContract(isDriver, vanpoolId, history, navigateTo) {
      dispatch({ type: APPLY_TO_SIGN_CONTRACT, data: { isDriver: isDriver, vanpoolId: vanpoolId, history, navigateTo } })
    },
    updateLoading(data) {
      dispatch({ type: LOADER_ACTIVE, data });
    },
    saveCommuteProfile(data) {
      dispatch({ type: SAVE_COMMUTE_PROFILE, data: data });
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentScreen);