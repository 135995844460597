import * as actionTypes from '../redux/actionTypes';
import { takeLatest, put, call, all, select } from 'redux-saga/effects';
import Http from '../shared/http-service';
import UserService from '../shared/user-service';
import logger from '../shared/logger';
import config from '../appConfig';
import LoginRedirectService from './loginRedirectService';
import profileService from './profileService';
import aggregateService from '../shared/aggregate/aggregate-service';
import DateUtility from '../shared/dateUtility';
import {
  PERMISSION_ACCOUNT_DASHBOARD_ADMIN,
  PERMISSION_VANPOOL_TRIP_RECORDING,
  PERMISSION_NTD_TRIP_RECORDING
} from '../shared/permissions/permissions';
import _ from 'lodash';
import {
  getVanPrice,
  getActiveDaysFromRoosterDays,
  getAvailableDaysOfVan
} from '../join-commute/helpers/vanHelpers';
import MapService from '../shared/map-service';
import AddressUtility from '../shared/addressUtility';
import dateUtility from '../shared/dateUtility';

export default function*() {
  // yield takeLatest(actionTypes.GET_ACCESS_TOKEN, getAccessToken);
  yield takeLatest(actionTypes.AUTHENTICATION_REQUEST, authenticateUser);
  yield takeLatest(actionTypes.CLEAR_AUTHENTICATION, clearAuthentication);
  yield takeLatest(actionTypes.AUTHORIZATION_REQUEST, authorizeUser);
  yield takeLatest(actionTypes.STATS_AGGREGATE_CHANGED, updateAuthoriztion);
  yield takeLatest(actionTypes.UPDATE_USER_PROFILE_DATA, updateUserProfile);
  yield takeLatest(
    actionTypes.SAVE_COMMUTE_PROFILE,
    saveCommuteAfterUserPreference
  );
}

function* authorizeUser(data) {
  try {
    const [userProfile, registration] = yield all([
      call(() => Http.get(`${config.kongApiBaseUrl}/user/loginProfile`)),
      call(() =>
        Http.get(`${config.kongApiBaseUrl}/user/profile/registration/status`)
      )
    ]);
    userProfile.registration = registration;
    const loginProfile = profileService.generateProfile(userProfile);
    loginProfile.isLead = data.userid ? true : false;
    loginProfile.isPrivateVanpoolSearch = data.privateVanpoolSearch ? true : false
    loginProfile.inviteId = data.inviteId ? true : false;
    loginProfile.isTraditional = data.isTraditional ? true : false;
    var initialFlow;
    yield put({ type: actionTypes.SET_USER_AUTHORIZATION, data: loginProfile });
  
    if (data.userid) {
      yield call(() => getUserPreferences(data.userid, data.isTraditional));
      initialFlow = true;
    }

    if(data.privateVanpoolSearch && !data.userid) {
      var searchCriteria = UserService.getPrivateSearchCriteria();
      yield put({ type: actionTypes.LOADER_ACTIVE, data:true });
      yield put({ type: actionTypes.PRIVATE_VANPOOL_SEARCH, data: searchCriteria });
      initialFlow =  true;
    }

    if(data.inviteId) {
      yield put({ type: actionTypes.INVITE_VANPOOL_SEARCH, data: {inviteId: data.inviteId} });
      initialFlow =  true;
    }
    //14B3D8D8-D3E2-F725-3F71-7E825F28
    //E0C78C2D-3D70-742C-7F50-7E84C252
    //yield put({ type: actionTypes.INVITE_VANPOOL_SEARCH, data: {inviteId: '14B3D8D8-D3E2-F725-3F71-7E825F28'} });
    //initialFlow =  true;
    var link = localStorage.getItem('deeplink');
    let path,deeplink;
    if (link && !data.userid && !data.privateVanpoolSearch) {
      path = link
      deeplink = true;
    }
    //  else {
    //   path = data.userid ? '/JoinCommute/add-payment' : '/';
    //   deeplink = false;
    // }
  

    yield put({
      type: actionTypes.AUTHENTICATION_SUCCESS,
      data: {
        redirectUrl: LoginRedirectService.getRedirectUrl(
          loginProfile,
          path,
          deeplink,
          initialFlow
        )
      }
    });

    var sessionPath = localStorage.getItem('path');
    if (sessionPath) {
      localStorage.removeItem('path');
      window.location.href = sessionPath;
    }


    return loginProfile;
  } catch (ex) {
    logger.log(ex);
    yield put({ type: actionTypes.AUTHENTICATION_ERROR, data: ex });
  }
}

function* updateAuthoriztion(action) {
  const currentAggregate = action.data;
  const currentAggregateIndex = aggregateService.getAggregateIndex(
    currentAggregate
  );
  const rootAggregate = aggregateService.getRootAggregate(currentAggregate);
  const profile = UserService.getUserProfile();
  const vanpool = aggregateService.getVanpool(
    currentAggregate,
    currentAggregateIndex,
    rootAggregate.isTransit
  );
  profile.permissions = _.filter(
    profile.permissions,
    permission =>
      permission !== PERMISSION_ACCOUNT_DASHBOARD_ADMIN &&
      permission !== PERMISSION_VANPOOL_TRIP_RECORDING &&
      permission !== PERMISSION_NTD_TRIP_RECORDING
  );

  profile.vanpoolId = vanpool ? vanpool.id : null;
  UserService.setAuthorization(profile);
  if (vanpool && vanpool.isAdministrable && rootAggregate.isAdmin) {
    const permissions = [
      ...profile.permissions,
      PERMISSION_ACCOUNT_DASHBOARD_ADMIN
    ];
    if (profile.vanpoolId) {
      const vanpoolDetails = yield call(() =>
        Http.get(`${config.kongApiBaseUrl}/vanpools/vanpool`)
      );
      if (vanpoolDetails && vanpoolDetails.isTripRecordingVanpool) {
        permissions.push(PERMISSION_VANPOOL_TRIP_RECORDING);
      }
      if (vanpoolDetails && vanpoolDetails.isVanpoolNTDReporting) {
        permissions.push(PERMISSION_NTD_TRIP_RECORDING);
      }
    }
    profile.permissions = permissions;
  }

  yield put({ type: actionTypes.SET_USER_AUTHORIZATION, data: profile });
}

function* authenticateUser(request) {
  try {
    let authData = request.data;
    const authToken = yield call(() =>
      Http.post(
        config.loginUrl,
        {
          oAuthusername: authData.username,
          oAuthpassword: authData.password,
          apiKeySharedSecret: config.apiKeySharedSecret,
          oAuthScope: config.apiScope
        },
        { apiKey: config.apiKey }
      )
    );
    if (authToken) {
      document.cookie = 'ehiToken=' + authToken.access_token + ';path=/';
      UserService.setAuthentication(authToken);
      UserService.setAccessToken(true);
      var data = {
        userid: ''
      };
      yield call(() => authorizeUser(data));

      yield put({ type: actionTypes.CLOSE_BANNER });
    } else {
      yield put({ type: actionTypes.AUTHENTICATION_FAILED });
    }
  } catch (ex) {
    logger.log(ex);
    yield put({ type: actionTypes.AUTHENTICATION_ERROR, data: ex });
  }
}

function clearAuthentication() {
  UserService.clearAuthentication();
}

function* updateUserProfile() {
  const state = yield select();
  const isCoordinatorInvite =  state.joinCommute.isCoordinatorInvite;
  try {
    const [userProfile, registration] = yield all([
      call(() => Http.get(`${config.kongApiBaseUrl}/user/loginProfile`)),
      call(() =>
        Http.get(`${config.kongApiBaseUrl}/user/profile/registration/status`)
      )
    ]);
    userProfile.registration = registration;
    const loginProfile = profileService.generateProfile(userProfile);
    loginProfile.isCoordinatorInvite = isCoordinatorInvite;
    
    yield put({ type: actionTypes.SET_USER_AUTHORIZATION, data: loginProfile });
    return loginProfile;
  } catch (ex) {
    logger.log(ex);
    yield put({ type: actionTypes.AUTHENTICATION_ERROR, data: ex });
  }
}

function* getUserPreferences(userId,isTraditional) {
  try {
    const preferencesURL = `${config.kongApiBaseUrl}/user/preferences/${userId}`;
    let response = yield call(() => Http.get(preferencesURL));
    if (response) {
      const startAddress = yield call(() => MapService.getAddressAndLocation(response.originAddress));
      const homeAddressExpanded = AddressUtility.fromGeocode(startAddress);
        response.homeAddress = response.originAddress;
        response.workAddress = response.destinationAddress;
        response.homeAddressExpanded = homeAddressExpanded;
        var isDriver =
          response.vanpoolRates[0].rates[0].discountType == 'Driver'
            ? true
            : false;
        var packageName = isTraditional ? null :
          response.vanpoolRates[0].rates[0].packages[0].packageName;
        var chargeObj = isTraditional ? null : getVanPrice(response, isDriver, packageName);
        var isPredelivery = (response.startDate == 'false' || response.startDate == "" || response.startDate == false);
        // var startDate = DateUtility.getMoment(response.startDate,"YYYY-MM-DD").toDate();
        var startDate = isTraditional  ||  isPredelivery ? null : DateUtility.getMoment(response.startDate).toDate();
        response.startDate = startDate;
        response.charge = isTraditional ? null : chargeObj?.totalAmount;
        response.package = packageName;
        response.fromBroucher = true;
        var selectedVan = {
          charge: chargeObj,
          riderCharge:chargeObj,
          vanpoolRates: response.vanpoolRates,
          vanpoolId: response.vanpoolId,
          originAddress: response.originAddress,
          destinationAddress: response.destinationAddress,
          carClassImage: response.carClassImage,
          vehicleClass: response.vehicleClass,
          ymms: response.ymms,
          isPredelivery: isPredelivery,
          matchedOriginStop: response.matchedOriginStop,
          matchedDestinationStop: response.matchedDestinationStop,
          isSubsidized: response.isSubsidized ? response.isSubsidized : false
        };
        var employerData = {
          employerId: response.employerId,
          employerName: response.employerName
        };
        const commuteDays = getActiveDaysFromRoosterDays(response.rosterDays);
        var plan = {
          noOfDays: commuteDays.length,
          isDriver: isDriver
        };
        var tempObj = {
          isDesiredDriverRole: isDriver ? true : false,
          role: isDriver ? 0 : 2,
          isFrequentDriver: response.isFrequentDriver ? response.isFrequentDriver : null
        };
        yield put({
          type: actionTypes.SET_USER_EMPLOYER_DATA,
          data: employerData
        });
        yield put({ type: actionTypes.SET_SELECTED_VAN, data: selectedVan });
        yield put({ type: actionTypes.UPDATE_USER_COMMUTE_DATA, data: response, updateOldPlan: true });
        const startDateString = dateUtility.getDateStringFromDay(startDate);
        yield put({ type: actionTypes.UPDATE_START_DATE, data: {startDate, startDateString} });
        yield put({ type: actionTypes.SET_PLAN_DATA, data: plan });
        yield put({ type: actionTypes.SET_USER_DRIVING_SCHEDULE, data: tempObj });
        yield put({ type: actionTypes.SAVE_AGREEMENT_DETAILS, data: {fromBroucher:true }});
        yield call(() => saveCommuteAfterUserPreference(response));
        if(isTraditional){
          yield put({ type: actionTypes.SET_TRADITIONAL_LEAD, data: isTraditional });
        }
    }
  } catch (ex) {
    logger.log(ex);
  }
}

function* saveCommuteAfterUserPreference(details) {
  var data = details.data ? details.data : details;
  const commuteUrl = `${config.kongApiBaseUrl}/user/profile/commuteProfile`;
  const profile = UserService.getUserProfile();
  const userInfo = UserService.getAuthentication();

  let payload = {
    referralChannel: 'Friend',
    phone: profile.phone,
    defaultTransportationModeId: 2,
    originLatitude: data.matchedOriginStop.latitude,
    originLongitude: data.matchedOriginStop.longitude,
    originArrivalTime: data.matchedOriginStop.arrivalTimestamp,
    originDepartureTime: data.matchedOriginStop.departureTimestamp,
    originAddress: data.originAddress,
    destinationAddress: data.destinationAddress,
    destinationArrivalTime: data.matchedDestinationStop.arrivalTimestamp,
    destinationLongitude: data.matchedDestinationStop.longitude,
    destinationLatitude: data.matchedDestinationStop.latitude,
    destinationDepartureTime: data.matchedDestinationStop.departureTimestamp,
    employerName: data.employerName,
    profileId: userInfo.profileId,
    isAllowNotifyCommuteMatch: false,
    isWorkDepartTimeFlexible: data.flexArrivalMinutes
      ? data.flexArrivalMinutes
      : 0,
    worksiteId: null,
    employerId: data.employerId,
    commuteDays: getActiveDaysFromRoosterDays(data.rosterDays),
    isWorkArriveTimeFlexible: data.flexDepartureMinutes
      ? data.flexDepartureMinutes
      : 0
  };
  try {
    yield call(() => Http.put(commuteUrl, payload));
    yield put({ type: actionTypes.COMMUTE_PROFILE_SAVED });
  } catch (ex) {
    logger.log(ex);
  }
}