import React, { useState } from 'react';
import ChevronUp from '../../../_styles/images/new-icons/chevron-up.png';
import { numberWithCommas } from '../../../join-commute/helpers/commonHelper';
import Tooltip from '../../../shared/Tooltip';
import Steering from '../../../_styles/images/Steering.png';
import Seat from '../../../_styles/images/GreenSeat.png';
import { makeDecimal2numbers } from '../../../payment/helper';
import PriceDisclaimerPopup from '../../../join-commute/subscription/PriceDisclaimerPopup';

const PriceDetails = ({ total, taxTotal, charge, taxes, surcharges, rateMessage, isDesiredDriverRole }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [showPDPopup, updatePDPopup] = useState(false);

    const toggleAccordion = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className='price-accordion'>
            <button className={`accordion top ${isOpen ? 'active' : ''}`} onClick={toggleAccordion} aria-expanded={isOpen} aria-controls="taxes-panel">
                <span className='description'><img src={ChevronUp} alt={isOpen ? 'Collapse price details' : 'Expand price details'} className={`caret ${isOpen ? '' : 'rotate'}`} />Price Details</span>
            </button>
            <div className={isOpen ? 'panel active' : 'panel'} id="taxes-panel" role="region" aria-labelledby="taxes-button">
                <div className='rate-accordion'>
                    <div className="accordion-wrapper">
                        <div className='accordion'>
                            <div className='description rate'>
                                <img
                                    src={isDesiredDriverRole ? Steering : Seat}
                                    className='icon'
                                    aria-hidden='true'
                                />
                                <span className='rate-desc'>{isDesiredDriverRole ? 'Driver' : 'Rider'} rate </span>
                            </div>
                            {charge && (
                                <div className='price' aria-label={`${charge} dollars`}>
                                    <div className='dollar'>$</div>
                                    <div>{numberWithCommas(makeDecimal2numbers(charge))}</div>
                                </div>
                            )}
                        </div>
                        {rateMessage && <Tooltip tooltipLabel='rate' message={rateMessage} newIcon={true} />}
                    </div>
                    <div>
                        <div className='tax-description' role='group'>
                            <div className='description'>Fuel</div>
                            <div className='amount'>
                                <div>Included</div>
                            </div>
                        </div>
                        <div className='tax-description' role='group'>
                            <div className='description'>Maintenance</div>
                            <div className='amount'>
                                <div>Included</div>
                            </div>
                        </div>
                        <div className='tax-description' role='group'>
                            <div className='description'>Roadside Assistance</div>
                            <div className='amount'>
                                <div>Included</div>
                            </div>
                        </div>
                        <div className='tax-description' role='group'>
                            <div className='description'>Insurance</div>
                            <div className='amount'>
                                <div>Included</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='tax-accordion'>
                    <div className='accordion'>
                        <span className='description tax'>Taxes and Fees</span>
                        <span className='total'><div className='dollar'>$</div>{numberWithCommas(parseFloat(taxTotal).toFixed(2))}</span>
                    </div>
                    {taxes && taxes.map((tax, index) => (
                        <div key={index} className='tax-description' role='group'>
                            <div className='description'>{tax.description}</div>
                            <div className='amount'><div className='dollar'>$</div>{numberWithCommas(parseFloat(tax.amount).toFixed(2))}</div>
                        </div>
                    ))}
                    {surcharges && surcharges.map((surcharge, index) => (
                        <div key={index} className='surcharges-description' role='group'>
                            <div className='description'>{surcharge.description}</div>
                            <div className='amount'><div className='dollar'>$</div>{numberWithCommas(parseFloat(surcharge.amount).toFixed(2))}</div>
                        </div>
                    ))}
                </div>
                <div className='bottom' role='group'>
                    <div className='disclaimer' role='group'>
                        <p>
                            Recurring subscription. <br />
                            Rates subject to change.&nbsp;
                            <span onClick={() => updatePDPopup(true)}>Learn More</span>
                        </p>
                    </div>
                    <div className='total' role='group'>
                        <span className='total-label'>Total</span>
                        <span className='grp'>
                            <div className='total-price' aria-label={`${total.price} dollars and ${total.decimal} cents total per month`}>
                                <div className='dollar'>$</div>
                                <div className='price'>{total.price && numberWithCommas(total.price) || '0'}</div>
                                <div className='decimal'>.{total.decimal || '00'}</div>
                            </div>
                            <div className='frequency'>per month</div>
                        </span>
                    </div>
                </div>
            </div>
            {
                showPDPopup && <PriceDisclaimerPopup updatePDPopup={() => updatePDPopup(false)} />
            }
        </div>
    );
};

export default PriceDetails;
