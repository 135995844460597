import {FULL_MONTHS} from '../shared/inputs/Calendar/Calendar';

export function getPaymentsList(arr) {
  if(arr) {
    let obj = {};
    let newArr = [];
    arr.map(group => {
      let year = getYearFromGroupTitle(group.groupTitle)
      if(!obj[year]) {
        obj[year] = [...group.paymentList]
      } else {
        obj[year] = [...obj[year],...group.paymentList]
      }
    })
    const years = Object.keys(obj);
    years.forEach((yr) => {
      newArr.push({key: yr,value: obj[yr]});
    })
    return newArr;
  }
  return [];
}

function getYearFromGroupTitle(title) {
  const splits = title.split(' ');
  return splits[1];
}

export function getOrdinalSuffix(day) {
  if (day > 3 && day < 21) return 'th';
  switch (day % 10) {
      case 1: return 'st';
      case 2: return 'nd';
      case 3: return 'rd';
      default: return 'th';
  }
}

export function getDateObjFromString(str) {
  if(str && typeof str === 'string'){
    const splits = str.split('-');
    if(splits.length === 3) {
      const mon = parseInt(splits[1]);
      const day = parseInt(splits[2]);
      const month = FULL_MONTHS[mon-1];
      return {
        year: splits[0],
        month,
        day,
        monthNumber: mon,
        date: new Date(splits[0], mon-1, day),
        dateSuffix : day && getOrdinalSuffix(parseInt(day)),
        monthSubstr: month.substring(0,3)
      }
    }
  }
  return {};
}

export function getCardInfo(cardProps) {
  if(cardProps) {
    return {
      cardNumber: cardProps.cardNumber || '',
      cardType: cardProps.cardType || '',
      cvv: cardProps.cvv || '',
      expiry: cardProps.expirationDate || '',
      zip: cardProps.zip || '',
      isFederal: cardProps.isFederal || false,
      isBackup: cardProps.isBackup || false,
      isDefault: cardProps.isDefault || false,
      maxAuthorizedAmount: cardProps.maxAuthorizedAmount || 0
    };
  }
  return {};
}

export function getDateString(obj) {
  const day = obj.day < 10 ? `0${obj.day}` : obj.day;
  const month = obj.monthNumber+1;
  const monthNumber = month < 10 ? `0${month}` : month;
  return `${obj.year}-${monthNumber}-${day}`;
}

export const formatDateToReadable = (dateString) => {
  const dateObj = getDateObjFromString(dateString);
  return `${dateObj.monthSubstr} ${dateObj.day}, ${dateObj.year}`;
};
