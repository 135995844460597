import TooltipLink from '../tooltip-link/TooltipLink';

export default function FederalComponent(props) {
  function onChange(event) {
    const val = event.target.value;
    const num = parseInt(val);
    if (isNaN(num)) {
      props.onUpdateAuthorizedAmount('');
    } else if (/^\d*$/.test(val)) {
      props.onUpdateAuthorizedAmount(num);
      if (num > props.maxAmount || num === 0) {
        props.updateIsAmountValid(false);
      } else {
        props.updateIsAmountValid(true);
      }
    }
  }

  return (
    <div className={`federal-container ${props.isCvvZipValid ? 'zip-cvv-invalid' : ''}`} role='group' aria-labelledby="federal-heading">
      <h3 id="federal-heading" className='heading'>This Is a Federal Benefit Card</h3>
      <div className='comment'>Please input the current monthly authorized amount for this card.</div>
      {props.findAVan && <div className='comment'>If the authorized amount is less than the cost of your subscription, you will be prompted to add a credit card to cover the cost.</div>}
      <fieldset className='card-input-container'>
        <label htmlFor='auth-amount' className='label'>Monthly Authorized Amount*</label>
        <input id='auth-amount' className={(props.authorizedAmount !== '' && !props.isAmountValid) ? 'error' : ''} value={props.authorizedAmount} onChange={onChange} aria-invalid={!props.isAmountValid}
          aria-describedby={!props.isAmountValid && props.authorizedAmount !== '' ? 'error-message' : undefined} />
        {!!props.authorizedAmount && !props.isAmountValid && <div className='error-message' role="alert" aria-errormessage='Enter amount less than maximum authorized limit' aria-live="assertive">Please input an amount under the ${Math.trunc(props.maxAmount)} federal benefit card maximum monthly limit</div>}
        {props.authorizedAmount === 0 && !props.isAmountValid && <div className='error-message' role="alert" aria-errormessage='Please enter an amount greater than 0' aria-live="assertive">Please enter an amount greater than 0</div>}
      </fieldset>
      <div className='tooltips-container'>
        <TooltipLink message="If you do not know your monthly authorized amount, please call the 1-800 number on the back of your card" linkText="How do I find this?" />
        <TooltipLink message="Reach out to your federal benefit point of contact about increasing your monthly authorized amount" linkText="How can I increase my authorized amount?" />
      </div>
    </div>
  );
}