import React, { Component } from 'react';
import { Pencil, Home, Briefcase, Vanpool } from '../../shared/icons';
import { RestrictTo } from '../../shared/permissions/RestrictTo';
import {
  TRADITIONAL_COORDINATOR_PERMISSION
} from '../../shared/permissions/permissions';
import LocationGreen from '../../_styles/images/LocationGreen.png';
import startLocation from '../../_styles/images/Subtract.png';
import Line from '../../_styles/images/Line.png';
import dateUtility from '../../shared/dateUtility';
import {shouldShowAddress, shouldShowAddressToParticipant} from '../../profile/helper';

export default class NewParticipantViewer extends Component {
  render() {
    const {
      rideDays,
      toHomeEnd,
      toHomeStart,
      toWorkEnd,
      toWorkStart,
      edit,
      participantId,
      currentParticipantID
    } = this.props;

    return (
      <div className="participant-route">
        <div className="route-days">
          <div className="flex-grow">
            <div className="schedule-label">Commute Details</div>
            <div className='commute-days'>
            <div className='commute-label'>Commute days</div>
            <div className='days'>
              {rideDays.map(day => (
                <div key={day} className="day-btn" aria-label={day} role='text'>
                  {day.substr(0, 3)}
                </div>
              ))}
            </div>
            </div>

          </div>
          <RestrictTo
            roles={[
                TRADITIONAL_COORDINATOR_PERMISSION
            ]}
            required={false}
          >
            {/* <Pencil onClick={edit} className="edit-icon" /> */}
            <div className="edit-icon" onClick={edit}>EDIT</div>
          </RestrictTo>
        </div>
        <div className="route-set">
          <div className="to-work route-type">
            <div className='route-label'>
                To Work
            </div>
            <ParticipantRoute start={toWorkStart} end={toWorkEnd} participantId={participantId} currentParticipant = {currentParticipantID} />
          </div>
          <div className="to-home route-type">
            <div className='route-label'>
             To Home
            </div>
            <ParticipantRoute start={toHomeStart} end={toHomeEnd} participantId={participantId} currentParticipant = {currentParticipantID}/>
          </div>
        </div>
      </div>
    );
  }
}

const getAddress = (addressObj, participantID, currentParticipantID, isStart) => {
  if(shouldShowAddressToParticipant(addressObj, currentParticipantID, participantID)){
    return (<div className="start-address" aria-label={`${(isStart ? 'start address' : 'end address') + addressObj.address}`}>{addressObj.address}</div>)
  }
  return (<div className="start-address" aria-label={`Private Address`}>Private Address</div>)
}

function ParticipantRoute({ start, end, participantId, currentParticipant }) {
  return start && end ? (
    <div className="van-route-details">
    <div className="route-icon" aria-hidden='true'>
      <img className="group" src={startLocation} />
      <img className="line" src={Line} />
      <img className="LocationGreen" src={LocationGreen} />
    </div>
    <div className="route-address" role='group'>
      <div className="origin-address" role='group'>
        {getAddress(start, participantId, currentParticipant, true)}
        <div className="start-timings">{`Depart: ${dateUtility.parseTime(start.stopTime)}`}</div>
      </div>
      <div className="destination-address" role='group'>
        {getAddress(end, participantId, currentParticipant, false)}
        <div className="end-timings">{`Arrive: ${dateUtility.parseTime(end.stopTime)}`}</div>
      </div>
    </div>
  </div>
  ) : null;
}
