import {useEffect, useState} from 'react'
import Phone from '../../_styles/images/Phone.png';
import DollarSign from '../../_styles/images/DollarSign.png';


export default function TraditionalPopup(props) {

  return (
    <div className="vanpool-popup-container">
        <div className="heading">{"You have selected an everyday commute"}</div>
        <div className="title">{"Check the availability in this vanpool to determine pricing and to join"}</div>
        <div className="container">
                <div className="section">
                    <div className="icon">
                    <img src={Phone} alt='phone' />
                    </div>
                    <div className="details">
                        {"A Commute with Enterprise team member will work with you to save your seat"}
                    </div>
                </div>
                {
                    !props.isIPB &&
                    <div className="section traditional">
                      <div className="icon">
                      <img src={DollarSign} alt='dollar' />
                      </div>
                      <div className="details">
                              <span aria-label="Footnote" aria-describedby="footnote-detail">*</span>
                              <span>
                              {"Estimate only: Your portion of the overall monthly vanpool rate will be determined by your vanpool. Rate shown includes estimates for fuel, applicable taxes, and add-on equipment.  Rate shown does not include your share of any applicable toll road costs. You may cancel online or by telephone with 30 days prior notice."}
                              </span>
                      </div>
                    </div>
                }

        </div>
        <div className="button">
            <button className="continue" onClick={props.saveTraditionalInfo}> {"continue"}</button>
        </div>
    </div>   
  )
}

