import React from 'react';
import { RestrictFrom, RestrictTo , isAllowed} from '../shared/permissions/RestrictTo';
import { PERMISSION_ACCOUNT_DASHBOARD_ADMIN,PERMISSION_BILLING_VIEWER,PERMISSION_COORDINATOR,PERMISSION_IPB,PERMISSION_REGISTERED_USER } from '../shared/permissions/permissions';
import { Link } from 'react-router-dom';

const AccountSummaryMakePayment = ({ remainingAccountBalanceDue }) => {
  return (
    <div className="account-summary-container balance right">
    {
      !isAllowed([PERMISSION_IPB]) ? 

       <RestrictTo  roles={[PERMISSION_BILLING_VIEWER,PERMISSION_COORDINATOR]}
          required={false} >
          <div id="balance-due-amount" className="amount">
          {remainingAccountBalanceDue}
          </div>
          <p id="balance-due-date" className="note">
          Current Account Balance
          </p>
        </RestrictTo>
        :
        ""
    }    
      <RestrictFrom
        roles={[PERMISSION_ACCOUNT_DASHBOARD_ADMIN,PERMISSION_REGISTERED_USER]}
        required={false}
      >
        <div className="tools">
          <Link className="primary button" to="/payment/pay">
            Make a Payment
          </Link>
          <Link className="recurring-payment-link" to="/payment/pay/recurring">
            Set up a recurring payment
          </Link>
        </div>
      </RestrictFrom>
    </div>
  );
};

export default AccountSummaryMakePayment;
