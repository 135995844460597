import { useEffect, useState } from 'react';
import { AddressInput } from '../../../shared/AddressInput';
import AddressUtility from '../../../shared/addressUtility';
import { EMPTY_ADDRESS } from '../../../shared/na';

export default function AddressSection(props) {

  const [address, updateAddress] = useState(EMPTY_ADDRESS);
  useEffect(() => {
    if (props && props.address) {
      updateAddress(props.address);
    }
  }, [props.address])

  function onChange(addr, valid) {
    if(addr){
      const updatedAddress = AddressUtility.fromGeocode(addr);
      props.onChange(updatedAddress, valid);
    }
  }

  function onLineChange(event) {
    if(event && event.target){
      const updatedAddress = {
        ...address,
        line2: event.target.value
      }
      props.onChange(updatedAddress, true);
    }
  }

  return (
    <div className='address-section'>
      <div className='names'>
        <fieldset className='field address1' aria-required="true">
            {
              props.label ?
                <label className='label'>{props.label}</label>
                :
                <label className='label'>Address*</label>
            }
          <AddressInput
            placeholder="Address"
            value={address.line1}
            onChange={(addr, valid) => onChange(addr, valid)}
            invalidmessage="Please enter a valid address"
            aria-describedby="address-line1"
          />
        </fieldset>
        <fieldset className='field address2'>
          <div className='label'>
            <label>Address Line 2</label>
          </div>
          <input value={address.line2 || ''} onChange={onLineChange} placeholder='Apartment, Suite' aria-describedby="address-line2"></input>
        </fieldset>
        <fieldset className='field city'>
          <div className='label'>
            <label>City*</label>
          </div>
          <input value={address.city} disabled={true} aria-disabled="true" aria-describedby="address-city" aria-label="City" aria-required="true"></input>
        </fieldset>
        <div className='small-address'>
          <fieldset className='field state'>
            <div className='label'>
              <label>State*</label>
            </div>
            <input value={address.state} disabled={true} aria-disabled="true" aria-describedby="address-state" aria-label="State" aria-required="true"></input>
          </fieldset>
          <fieldset className='field zip'>
            <div className='label'>
              <label>Zip Code*</label>
            </div>
            <input value={address.zip} disabled={true} aria-disabled="true" aria-describedby="address-zip" aria-label="Zip Code" aria-required="true"></input>
          </fieldset>
        </div>

      </div>
    </div>
  );
}