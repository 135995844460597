import storage from 'redux-persist/lib/storage';
import persistExpire from 'redux-persist-expire';
import { initialJoinCommuteState } from '../../join-commute/JoinCommuteReducer';
import createTransform from 'redux-persist/lib/createTransform';

const startDateTransform = createTransform(
  (inboundState) => ({
    ...inboundState,
    userCommuteData: {
      ...inboundState.userCommuteData,
      startDate: inboundState.userCommuteData.startDate instanceof Date ? inboundState.userCommuteData.startDate.toISOString() : inboundState.date,
    }
  }),
  (outboundState) => ({
    ...outboundState,
    userCommuteData: {
      ...outboundState.userCommuteData,
      startDate: outboundState.userCommuteData.startDate ? new Date(outboundState.userCommuteData.startDate) : null,
    }
  }),
  { whitelist: ['joinCommuteReducer'] }
);

export const JoinCommutePersistConfig = {
    key: 'joinCommuteState',
    storage: storage,
    transforms: [
        persistExpire({
          expireSeconds: 3600,
          expiredState: initialJoinCommuteState,
          autoExpire: true,
        }),
        startDateTransform,
    ],
    blacklist: ['payment']
};

const persistLicenseData = createTransform(
  (inboundState, key) => {
    return { driverApplicationData: inboundState.driverApplicationData };
  },
  (outboundState, key) => {
    return { driverApplicationData: outboundState.driverApplicationData };
  },
  { whitelist: ['profileReducer'] }
);

export const ProfilePersistConfig = {
    key: 'profileState',
    storage: storage,
    transforms: [persistLicenseData],
    whitelist: ['driverApplicationData']
};
