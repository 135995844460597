import { useEffect } from 'react';
import Close from '../../_styles/images/close-icon-green.svg';
import Callable from '../../shared/Callable';
import Info from '../../_styles/images/RoundInfo.png';
import analyticsService from '../../shared/analytics/analytics-service';


export default function InfoChangedPopup(props) {

  useEffect(() => {
    analyticsService.analyticsProcessEvent(
      {
        "event": "modal_view_generic",
        "context": {
          "event_action": "account information change modal"
         }
      })
  }, []);

  function onCloseKeyDown(e) {
    if (e.key === 'Enter' || e.key === ' ') {
      e.preventDefault();
      props.close();
    }
  }

  return (
    <div className='email-changed show' role='alert' id='email-changed-popup' aria-live='assertive'>
      <div className='email-changed-popup' ref={props.reference}>
        <div className='charges-header'>
          <img
            className='close'
            src={Close}
            onKeyDown={onCloseKeyDown}
            onClick={props.close}
            alt="Close popup"
            role='button'
            tabIndex={0}
            aria-label='Close popup'
          />
        </div>
        <div className='info-icon'>
        <img
            className='info-img'
            src={Info}
            alt="info icon"
            aria-label='info icon'
          />
        </div>
        <div className='email-changed-popup-content'>
        <div className='popup-title'>{"Account Infomation Change"}</div>
        <div className='pre-text'>
          <span>
              {"If your employer or email address has changed please call" + " "}
            </span>
            <Callable phone="800 VAN 4 WORK" />
            <span>
             {" " + "to update your profile and find a vanpool."}
            </span>
            </div>
        </div>
          <div className='button-container'>
            <button tabIndex={0} className='close-button' onClick={props.close}>Close</button>
          </div>
      </div>
    </div>
  );
}
